/* eslint-disable no-underscore-dangle */
import dayjs from "dayjs";
import { computed, makeObservable, observable } from "mobx";
import { hotjar } from "react-hotjar";
import { NavigateFunction } from "react-router-dom";
import { EngagementsApi, EpiserverApi, OffersApi, SantanderAuthApi, CustomerComplianceApi, ChatApi } from "../libs/api";
import { TransferApi } from "../libs/api/TransferApi";
import { tx } from "../libs/i18n";
import { IConfig } from "../libs/models/Config";
import { CardActionBlockTypes, LoanActionBlockTypes, PageType } from "../libs/models/Content/Enums";
import { IAccount } from "../libs/models/Engagements/Account";
import { IOffer, OfferCategory } from "../libs/models/Offer/Offer";
import { ContentStore } from "./ContentStore";
import { CreditStore } from "./CreditStore";
import { CustomerStore } from "./CustomerStore";
import { DepositStore } from "./DepositStore";
import { InvoiceStore } from "./InvoiceStore";
import { LeasingStore } from "./LeasingStore";
import { LetterStore } from "./LetterStore";
import { LoanStore } from "./LoanStore";
import { MenuStore } from "./MenuStore";
import { MessageStore } from "./MessageStore";
import { OfferStore } from "./OfferStore";
import { TransactionStore } from "./TransactionStore";
import { TransferStore } from "./TransferStore";
import { UIStore } from "./UIStore";
import { GlobalPersistentStore } from "./GlobalPersistentStore";
import { CustomerPersistentStore } from "./CustomerPersistentStore";
import { CommonService } from "../libs/models/CommonService";
import { IDocument } from "libs/models/Engagements";
import { IPlatformStore } from "libs/models/PlatformStore";
import { SecurePersistentStore } from "./SecurePersistentStore";
import { ComplianceStore } from "./ComplianceStore";
import { BankIdSignStore } from "./BankIdSignStore";
import { TelemetryStore } from "./TelemetryStore";
import { LoginEvent } from "../libs/models/Telemetry/LoginEvents";
import { UnhealthyServiceEvents } from "../libs/models/Telemetry/UnhealthyServiceEvents";

// TODO: Add NO/DK/FI for nordic solution
require("dayjs/locale/sv");

let store: Store | undefined;

export class Store {
  commonService: CommonService;

  episerverApi: EpiserverApi;

  authApi: SantanderAuthApi;

  engagementsApi: EngagementsApi;

  offersApi: OffersApi;

  transferApi: TransferApi;


  chatApi: ChatApi;

  customerComplianceApi: CustomerComplianceApi;

  globalPersistentStore: GlobalPersistentStore;

  securePersistentStore: SecurePersistentStore;

  customerPersistentStore: CustomerPersistentStore;

  uiStore: UIStore;

  menuStore: MenuStore;

  bankIdSignStore: BankIdSignStore;

  depositStore: DepositStore;

  leasingStore: LeasingStore;

  loanStore: LoanStore;

  creditStore: CreditStore;

  invoiceStore: InvoiceStore;

  messageStore: MessageStore;

  transactionStore: TransactionStore;

  customerStore: CustomerStore;

  contentStore: ContentStore;

  offerStore: OfferStore;

  rootStore: Store;

  transferStore: TransferStore;

  letterStore: LetterStore;

  complianceStore: ComplianceStore;

  config?: IConfig;

  platformStore?: IPlatformStore;

  telemetryStore: TelemetryStore;

  navigate?: NavigateFunction;


  storage: any;

  secureStorage: any;

  @observable
  allEngagements?: IAccount[] = undefined;

  @observable
  isMock = false;

  @observable
  loggingOut = false;

  @observable
  currentAccountNumber = "";

  @computed
  get currentAccount(): IAccount | undefined {
    return this.allEngagements?.find((acc) => acc.accountNumber === this.currentAccountNumber);
  }

  set currentAccount(account: IAccount | undefined) {
    this.currentAccountNumber = account?.accountNumber ?? "";
  }

  @computed
  get accounts() {
    return {
      creditAccounts: this.creditStore.creditAccounts,
      blockedCreditAccounts: this.creditStore.blockedCreditAccounts,
      depositAccounts: this.depositStore.depositAccounts,
      leasingAccounts: this.leasingStore.leasingAccounts,
      privateLoanAccounts: this.loanStore.privateLoanAccounts,
      securedLoanAccounts: this.loanStore.securedLoanAccounts,
      blockedPrivateLoanAccounts: this.loanStore.blockedPrivateLoanAccounts,
      blockedSecuredLoanAccounts: this.loanStore.blockedSecuredLoanAccounts,
    };
  }

  @computed
  get engagementsAndOffers() {
    return {
      offers: this.offerStore.offers,
      engagements: {
        creditAccounts: this.creditStore.creditAccounts,
        depositAccounts: this.depositStore.depositAccounts,
        leasingAccounts: this.leasingStore.leasingAccounts,
        loanAccounts: this.loanStore.loanAccounts,
      },
    };
  }

  @computed
  get templateData() {
    return {
      ...this.currentAccount,
      ...this.customerStore.currentCustomer,
      ...this.offerStore.templateData,
      ...this.loanStore.templateData,
    };
  }

  constructor(storage: any, secureStorage: any, commonService: CommonService, platformStore: IPlatformStore) {
    this.storage = storage;
    this.secureStorage = secureStorage;
    this.commonService = commonService;
    this.authApi = new SantanderAuthApi(this);
    this.globalPersistentStore = new GlobalPersistentStore(storage);
    this.platformStore = platformStore;
    this.uiStore = new UIStore(this, this.platformStore);
    this.customerPersistentStore = new CustomerPersistentStore(storage, this, this.uiStore);
    this.engagementsApi = new EngagementsApi(this, this.customerPersistentStore);
    this.chatApi = new ChatApi(this, this.customerPersistentStore);
    this.transferApi = new TransferApi(this);
    this.telemetryStore = new TelemetryStore(this.globalPersistentStore, this.platformStore);
    this.bankIdSignStore = new BankIdSignStore(this.authApi, this.transferApi, this.telemetryStore);
    this.customerStore = new CustomerStore(
      this.authApi,
      this.engagementsApi,
      this.chatApi,
      this,
      this.customerPersistentStore,
      this.bankIdSignStore
    );
    this.securePersistentStore = new SecurePersistentStore(secureStorage);
    this.episerverApi = new EpiserverApi(this, this.customerStore, this.customerPersistentStore);
    this.offersApi = new OffersApi(this);
    this.customerComplianceApi = new CustomerComplianceApi(this, this.customerPersistentStore);

    this.menuStore = new MenuStore();
    this.depositStore = new DepositStore(this.engagementsApi, this);
    this.leasingStore = new LeasingStore(this.engagementsApi, this);
    this.loanStore = new LoanStore(this.engagementsApi, this);
    this.invoiceStore = new InvoiceStore(this.engagementsApi, this.telemetryStore);

    this.transactionStore = new TransactionStore(this.engagementsApi);
    this.creditStore = new CreditStore(this.offersApi, this.engagementsApi, this);
    this.offerStore = new OfferStore(this.offersApi, this);

    this.messageStore = new MessageStore(this.engagementsApi, this.commonService);
    this.contentStore = new ContentStore(this, this.customerPersistentStore, this.episerverApi);

    this.transferStore = new TransferStore(
      this,
      this.transferApi,
      this.engagementsApi,
      this.creditStore,
      this.depositStore,
      this.uiStore,
      this.contentStore,
      this.bankIdSignStore
    );
    this.letterStore = new LetterStore(this.engagementsApi);
    this.complianceStore = new ComplianceStore(this.customerComplianceApi);
    this.rootStore = this;
    makeObservable(this);
  }

  onMount = async (config?: IConfig, window?: Window, navigate?: NavigateFunction) => {
    this.uiStore.onMount(window);
    // On first mount read the config and create api client instances.
    if (!this.config) {
      this.config = config;

      await this.securePersistentStore.hydrate();
      await this.globalPersistentStore.hydrate();
      if (this.globalPersistentStore.customerId && !this.customerPersistentStore.isHydrated) {
        await this.customerPersistentStore.hydrate(this.globalPersistentStore.customerId);
      }
      this.episerverApi.init(this.config);
      this.telemetryStore.init(this.config);
      this.authApi.init(this.config);
      this.engagementsApi.init(this.config);
      this.offersApi.init(this.config);
      this.transferApi.init(this.config);
      this.customerComplianceApi.init(this.config);
      this.chatApi.init(this.config);
      this.navigate = navigate;
      this.contentStore.siteUrl = this.config?.SITE_URL;
      this.setIsMock(this.globalPersistentStore.isMock || false);

      if (this.config?.HOTJAR_ID && this.config.HOTJAR_SV) {
        const hotjarId = parseInt(this.config?.HOTJAR_ID, 10);
        const hotjarSv = parseInt(this.config?.HOTJAR_SV, 10);

        if (!Number.isNaN(hotjarId) && !Number.isNaN(hotjarSv)) hotjar.initialize(hotjarId, hotjarSv);
      }
      this.uiStore.initEvents();
    }
    dayjs.locale(tx("routing.lang"));
    return this;
  };

  onUnmount = () => {
    this.uiStore.onUnmount();
  };

  setIsMock = (isMock: boolean) => {
    this.isMock = isMock;
    this.globalPersistentStore.setIsMock(isMock);
    this.engagementsApi.setMock(isMock);
    this.authApi.setMock(isMock);
    this.offersApi.setMock(isMock);
    this.transferApi.setMock(isMock);
    this.customerComplianceApi.setMock(isMock);
    this.chatApi.setMock(isMock);
  };

  getAccountByNumber = (accountNumber: string) => {
    return this.allEngagements?.find((acc) => acc.accountNumber === accountNumber);
  };

  getAccountByDisplayNumber = (displayNumber: string) => {
    return this.allEngagements?.find((acc) => acc.displayNumber === displayNumber);
  };

  getAccountByComplianceAccountId = (accountId: string): IAccount | undefined => {
    if (!accountId) return undefined;

    let displayNumber = "";

    if (/^\d+$/.test(accountId)) {
      // In STG accountId always matches the displayNumber of the account
      displayNumber = accountId;
    } else {
      // In production a core system prefix is added:
      // Match using regex Capturing group. accountId format examples:
      // - V21CVDK-210042595
      // - CMSWAY4DK-9352000478038
      const match = accountId.match(/[0-9a-zA-Z]+-(\d+)/);

      if (!match || match.length < 2) {
        return undefined;
      }
      // eslint-disable-next-line prefer-destructuring
      displayNumber = match[1];
    }

    return this.allEngagements?.find((acc) => acc.displayNumber === displayNumber);
  };

  async getEngagements(refresh = false) {
    const response = await this.engagementsApi.getEngagements(refresh);
    if (response?.ok && response.data) {
      const creditAccounts = response.data?.creditAccounts || [];
      const depositAccounts = response.data?.depositAccounts || [];
      const leasingAccounts = response.data?.leaseAccounts || [];
      const privateLoanAccounts = response.data?.privateLoanAccounts || [];
      const securedLoanAccounts = response.data?.securedLoanAccounts || [];

      const creditAccountsError = !!response.data?.creditAccountsError?.message;
      const depositAccountsError = !!response.data?.depositAccountsError?.message;
      const leasingAccountsError = !!response.data?.leaseAccountsError?.message;
      const privateLoanAccountsError = !!response.data?.privateLoanAccountsError?.message;
      const securedLoanAccountsError = !!response.data?.securedLoanAccountsError?.message;

      const loanAccounts = privateLoanAccounts.concat(securedLoanAccounts);
      const mappedCreditAccounts = creditAccounts.map((c) => {
        const isSalesFinance =
          c.accountNumber?.match(/^2\d+$/) && c.nameIB?.toLowerCase() === "butikskredit" && c.chainName;

        return {
          ...c,
          name: isSalesFinance ? c.chainName : c.nameIB || c.displayNumber || c.accountNumber,
        };
      });

      this.creditStore.creditAccounts = mappedCreditAccounts.filter((account) => !account.isDisabled);
      this.creditStore.blockedCreditAccounts = mappedCreditAccounts.filter((account) => account.isDisabled);

      this.depositStore.depositAccounts = depositAccounts;
      this.leasingStore.leasingAccounts = leasingAccounts;
      this.loanStore.loanAccounts = loanAccounts.filter((a) => !a.isDisabled);
      this.loanStore.securedLoanAccounts = securedLoanAccounts.filter((a) => !a.isDisabled);
      this.loanStore.privateLoanAccounts = privateLoanAccounts.filter((a) => !a.isDisabled);
      this.loanStore.blockedLoanAccounts = loanAccounts.filter((a) => a.isDisabled);
      this.loanStore.blockedSecuredLoanAccounts = securedLoanAccounts.filter((a) => a.isDisabled);
      this.loanStore.blockedPrivateLoanAccounts = privateLoanAccounts.filter((a) => a.isDisabled);

      this.creditStore.creditAccountsError = creditAccountsError;
      this.depositStore.depositAccountsError = depositAccountsError;
      this.leasingStore.leasingAccountsError = leasingAccountsError;
      this.loanStore.privateLoanAccountsError = privateLoanAccountsError;
      this.loanStore.securedLoanAccountsError = securedLoanAccountsError;
      const accountFetchErrors = {
        creditAccountsError,
        depositAccountsError,
        leasingAccountsError,
        privateLoanAccountsError,
        securedLoanAccountsError
      }

      if (Object.values(accountFetchErrors).some(x => x === true)) {
        this.telemetryStore.trackEvent(UnhealthyServiceEvents.EngagementsAccountsFetchHasErrors, accountFetchErrors)
      }

      this.allEngagements = [...creditAccounts, ...depositAccounts, ...leasingAccounts, ...loanAccounts];
    }
    else{
      this.telemetryStore.trackSimpleEvent(UnhealthyServiceEvents.EngagementsAccountsFetchFailed, "Request failed or has no data");
    }
  }

  getDocumentById = async (documentId: string): Promise<IDocument | undefined> => {
    const response = await this.engagementsApi.getDocument(documentId);
    if (response?.ok && response?.data) {
      return response.data;
    }

    return undefined;
  };

  customerCanActOn = (blockType?: string): boolean => {
    const pageType = this.contentStore.currentPage?.contentType?.[1];
    const standardPage = pageType === PageType.StandardPage || pageType === PageType.HomePage;

    if (!blockType) return false;

    switch (blockType) {
      case PageType.CardTransitPage:
        return this.creditStore.hasAccounts;
      case PageType.PrivateLoanTransitPage:
        return this.loanStore.hasPrivateLoanAccounts;
      case PageType.SecuredLoanTransitPage:
        return this.loanStore.hasSecuredLoanAccounts;
      case PageType.DepositTransitPage:
        return this.depositStore.hasAccounts;
      case PageType.LeasingTransitPage:
        return this.leasingStore.hasAccounts;
      case PageType.CardSettingsPage:
        return (
          !this.creditStore.cardOrderingDisabledForAllAccounts &&
          !!this.creditStore.creditAccounts &&
          this.creditStore.creditAccounts.length > 0
        );
      case LoanActionBlockTypes.AccordionTerminationAmountBlock:
        return !!this.loanStore.currentAccount?.canCalculateTerminationAmount;
      case CardActionBlockTypes.AccordionBlockCardBlock:
        return !standardPage ? this.creditStore.canBlockCurrentCard : this.creditStore.hasBlockableCards;
      case CardActionBlockTypes.AccordionActivateCardBlock:
        return !standardPage ? this.creditStore.canActivateCurrentCard : this.creditStore.hasInactiveCards;
      case CardActionBlockTypes.AccordionEvryBlock:
        return this.creditStore.canShowEveryBlockForCurrentAccount;
      case CardActionBlockTypes.AccordionOrderCardBlock:
        return !standardPage ? this.creditStore.canOrderCardForCurrentAccount : this.creditStore.canOrderCard;
      default:
        return true;
    }
  };

  getOfferAccount = (offer: IOffer): IAccount | undefined => {
    switch (offer.offerCategory) {
      case OfferCategory.LINC:
      case OfferCategory.PFMC:
      case OfferCategory.PPI:
      case OfferCategory.PPIC:
        return this.creditStore.creditAccounts?.find((acc) => offer?.accountNumber.indexOf(acc.accountNumber) !== -1);
      case OfferCategory.PFML:
      case OfferCategory.PPIL:
      case OfferCategory.AOL:
        return this.loanStore.loanAccounts?.find((acc) => offer?.accountNumber.indexOf(acc.displayNumber!) > -1);
      default:
        return undefined;
    }
  };

  logoutFromChat = async () => {
    if (this.customerStore.isGiosgInitialized) {
      const { giosgVisitorId, giosgRooms } = this.customerStore;
      await this.chatApi.endChat(giosgVisitorId, giosgRooms);
      if (window?._giosg) {
        window._giosg("visitor", "removeAll");
        window._giosg("chat", "close", { all: true });
      }
    }
  };

  logout = async (internal = false, reason: string) => {
    this.telemetryStore.trackSimpleEvent(LoginEvent.Logout, reason);
    this.loggingOut = true;
    const epiLogoutUrl = this.contentStore?.currentPage?.logoutLink?.[0]?.href;

    await this.logoutFromChat();
    await this.authApi.revokeToken();

    this.globalPersistentStore.resetStore();
    this.securePersistentStore.resetStore();
    this.customerPersistentStore.resetPartyData();
    this.allEngagements = undefined;
    this.currentAccountNumber = "";
    this.navigate = undefined;

    this.depositStore.resetStore();
    this.leasingStore.resetStore();
    this.loanStore.resetStore();
    this.invoiceStore.resetStore();
    this.transactionStore.resetStore();
    this.creditStore.resetStore();
    this.offerStore.resetStore();
    this.customerStore.resetStore();
    this.letterStore.resetStore();
    this.complianceStore.resetStore();
    this.menuStore.resetStore();
    this.contentStore.resetStore();
    this.transferStore.resetStore();
    this.messageStore.resetStore(true);
    this.commonService.handleLogout(epiLogoutUrl, internal);

    if (internal) {
      this.loggingOut = false;
    }
  };

  refreshToken = () => {
    this.customerStore.refreshToken();
  };

  setBankIdTimeoutError = (applyAction: () => void) => {
    const { currentPage } = this.contentStore;
    if (currentPage) {
      this.uiStore.setConfirmationPopup({
        header: currentPage.bankIdTimeoutErrorHeader,
        text: currentPage.bankIdTimeoutErrorText,
        applyText: currentPage.loginErrorTryAgainButton,
        applyAction: () => {
          this.uiStore.removeConfirmationPopup();
          applyAction();
        },
        closeText: currentPage.loginErrorCloseButton,
        closeAction: () => this.uiStore.removeConfirmationPopup(),
      });
    }
  };

  setBankIdGeneralError = (applyAction: () => void) => {
    const { currentPage } = this.contentStore;
    if (currentPage) {
      this.uiStore.setConfirmationPopup({
        header: currentPage.bankIdGeneralErrorHeader,
        text: currentPage.bankIdGeneralErrorText,
        applyText: currentPage.loginErrorTryAgainButton,
        applyAction: () => {
          this.uiStore.removeConfirmationPopup();
          applyAction();
        },
        closeText: currentPage.loginErrorCloseButton,
        closeAction: () => this.uiStore.removeConfirmationPopup(),
      });
    }
  };

  setNoCustomerError = (applyAction: () => void) => {
    const { currentPage } = this.contentStore;
    this.authApi.revokeToken();
    if (currentPage) {
      this.uiStore.setConfirmationPopup({
        header: currentPage.noCustomerErrorHeader,
        text: currentPage.noCustomerErrorText,
        applyText: currentPage.loginErrorTryAgainButton,
        applyAction: () => {
          this.uiStore.removeConfirmationPopup();
          applyAction();
        },
        closeText: currentPage.loginErrorCloseButton,
        closeAction: () => this.uiStore.removeConfirmationPopup(),
      });
    }
  };

  setIdleWarning = () => {
    const { currentPage } = this.contentStore;
    if (currentPage) {
      this.uiStore.setConfirmationPopup({
        header: currentPage.logoutWarningHeader,
        text: currentPage.logoutWarningText,
        applyText: currentPage.logoutWarningStayButton,
        applyAction: () => {
          this.uiStore.removeConfirmationPopup();
        },
        closeText: currentPage.logoutWarningLogoutButton,
        closeAction: async () => {
          this.uiStore.removeConfirmationPopup();
          await this.logout(false, "User initiated");
        },
      });
    }
  };

  bankIdRedirectUrl = () => {
    if (this.platformStore?.isApplication()) {
      return "mobilebank://bankIDLoginCallback";
    }

    if (this.platformStore?.isIOSChrome?.()) {
      return encodeURIComponent("googlechrome://");
    }

    if (this.platformStore?.isIOSFirefox?.()) {
      return encodeURIComponent("firefox://");
    }

    if (this.platformStore?.isIOSEdge?.()) {
      return encodeURIComponent("microsoft-edge-https://");
    }

    if (this.platformStore?.isiOSDevice?.()) {
      const { origin, pathname, search } = window.location;
      // If search is set there is no way to get automatic back redirection from bankid without refreshing the page
      if (search) return "null";

      return encodeURIComponent(`${origin}${pathname}#bankidIdentified&nonce=${this.bankIdSignStore.nonce}&startState=${this.bankIdSignStore.bankIdStartState}`);
    }

    return "null";
  };

  bankIdUrl = (token: string) => {
    return `bankid:///?autostarttoken=${token}&redirect=${this.bankIdRedirectUrl()}`;
  }

  openBankId = (token: string) => {
    this.commonService.redirectToBankId(this.bankIdUrl(token));
  };

  removeBankIdHash = (path?: string) => {
    if (this.platformStore?.isApplication()) {
      return;
    }
    // Only for web. Removes hash required by safari in order to come back to safari app without refreshing the page.
    if (window?.location.hash.includes("#bankidIdentified")) {
      const { pathname, hash, search } = window.location;
      const updatedPath = `${pathname}${hash}${search}`.replace(hash, "");
      this.commonService.redirect(path || updatedPath);
    }
  };
}

export const initStore = (
  storage: any,
  secureStorage: any,
  commonService: CommonService,
  platformStore: IPlatformStore
) => {
  store = store != null ? store : new Store(storage, secureStorage, commonService, platformStore);
  return store;
};

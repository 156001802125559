import { Locale } from "../../models/Content/Enums";

/* eslint-disable max-len */
export default {
  translation: {
    routing: {
      lang: "sv",
      locale: Locale.sv,
      loan: "lan",
      privateLoan: "privatlan",
      securedLoan: "bil-och-fritidslan",
      leasing: "leasing",
      card: "kort",
      deposit: "sparkonto",
      term: "villkor",
    },
    routeNames: {
      home: "Översikt",
      transfers: "Överföringar",
      messages: "Meddelanden",
      card: "Kort",
      loan: "Lån",
      deposit: "Sparkonto",
      leasing: "Leasing",
      menu: "Meny",
    },
    validators: {
      required: "Ange {{- name, lower}}",
      email: "Din angivna e-postadress är inte giltig",
      phone: "Ogiltigt {{- name, lower}}",
      pidn: "Ditt angivna personnummer är inte giltigt",
    },
    invoice: {
      currentInvoice: "Aktuell faktura",
      overdueAmount: "Förfallet belopp",
      movedToDebtCollection: "Flyttad till inkasso",
      accountName: "Kontonamn",
      accountNumber: "Kontonummer",
      autogiro: "Autogiro",
      bankgiro: "Bankgiro",
      customerNumber: "Kundnummer",
      loadMore: "Se fler fakturor",
      disclaimer: `För att inte bli debiterad ränta, betalar du {{- amount }} denna månad. Summan inkluderar räntebärande köp,
        kontantuttag samt eventuella avgifter.`,
      downloadInvoice: "Ladda hem PDF",
      showInvoice: "Visa faktura",
      dueDate: "Förfallodatum",
      invoice: "Faktura",
      invoiceAndDate: "Faktura {{- date, lower}}",
      invoiceDate: "Fakturadatum",
      invoiceDetails: "Fakturadetaljer",
      invoiceNumber: "Fakturanummer",
      invoicePeriod: "Fakturaperiod",
      invoiceArchive: "Fakturaarkiv",
      amountToPay: "Belopp att betala",
      minimumPayment: "Lägsta belopp att betala",
      maximumPayment: "Högsta belopp att betala",
      ocr: "Referensnummer (OCR)",
      plusGiro: "plusGiro",
      stopped: "Fakturan är stoppad.",
      stopText: `Vi har mottagit underlag gällande din reklamation. Faktureringen har stoppats i väntan på
        resultatet av den externa prövningen. Vid frågor kontakta oss på telefonr {{- phoneNumber }}.`,
      total: "Hela kreditbeloppet",
      balanceAtDate: "Saldo per den",
      seeInvoice: "Se faktura",
      isPartOfCommonInvoice: "Faktura är en del av gemensamt dokument. Vänligen betala endast en gång",
      invoiceDownload: "Ladda hem faktura",
      status: "Status",
      titles: {
        cancelled: "Produkten är uppsagd, {{- date, lower}}",
        overdue: "Förfallen faktura, {{- date, lower}}",
        currentPlaceholder: "Aktuell faktura",
        current: "Aktuell faktura, {{- date, lower}}",
        extraPayment: "Önskar du betala extra?",
        paymentFreeMonth: "Önskar du dig betalningsfri månad?",
      },
      statuses: {
        due: "Obetald",
        cancel: "Annulleras",
        paid: "Betald",
        overdue: "Förfallen",
        replaced: "Ersatt",
        pending: "Inom respit",
      },
      emptyState: {
        header: "Inga resultat hittades!",
        text: "",
      },
    },
    card: {
      accountNumber: "Kontonummer",
      accountName: "Kontonamn",
      accountType: "Kontotyp",
      brokenCard: "Trasigt kort",
      credit: "Kredit",
      coApplicant: "Medlåntagare",
      card: "Kort",
      insurance: "Försäkring",
      lostCard: "Förlorat kort",
      autogiro: "Autogiro",
      pfm: "Betalningsfri månad",
      cardNumber: "Kortnummer",
      expiresAt: "Giltigt till och med",
      creditLimit: "Kreditgräns",
      orderCard: "Beställ kort",
      order: "Beställ",
      hasCard: "Kort finns",
      activateCard: "Aktivera kort",
      manufacturingPending:
        "Ditt kort har beställts. Knappen för att aktivera kortet kommer att vara tillgänglig inom 2 bankdagar",
      status: "Kortstatus",
      blockCard: "Spärra kort",
      availableCredit: "Tillgänglig kredit",
      usedCredit: "Utnyttjad kredit",
      creditAmount: "Kreditbelopp",
      getCreditInsurance: "Teckna kreditskydd - en försäkring för ditt kort",
    },
    transfer: {
      yourAccounts: "Dina konton",
      otherAccounts: "Övriga konton",
      fromAccount: "Från konto",
      toAccount: "Till konto",
      amount: "Belopp",
      bank: "Bank",
      transferMoney: "Överför",
      accountNumber: "Kontonummer",
      addWithdrawalAccount: "Lägg till uttagskonto för sparkonto",
      addCreditCardCheckingAccount: "Lägg till uttagskonto för kreditkort",
      addNewAccount: "Lägg till uttagskonto",
      clearingNumber: "Clearingnummer",
      manageSavedAccounts: "Hantera sparade konton",
      addAccount: "Lägg till konto",
      availableAmount: "Tillgängligt belopp",
      ownedAccountsLabel: "Mina konton",
      savedAccountsLabel: "Mina sparade uttagskonton",
      newReceiverLabel: "Nytt uttagskonto",
      addNewReceiver: "Lägg till uttagskonto",
      chooseReceiver: "Välj mottagare...",
      amountToTransfer: "Belopp att överföra",
      bankTransferFee: "Uttagsavgift",
      balanceAfterTransfer: "Disponibelt efter överföring",
      noAmountError: "Kom ihåg att ange belopp du vill överföra.",
      noFromAccountError: "Kom ihåg att välja det konto du vill överföra från.",
      noToAccountError: "Kom ihåg att välja det konto du vill överföra till.",
      saveToAccountError: "Kom ihåg att spara det nya mottagarkontot.",
      savedToAccountResponseError:
        "Kunde inte spara mottagarkontot. Kontrollera att clearing- och kontonumret stämmer. Om felet kvarstår kontakta kundtjänst.",
      amountError: "Du kan inte föra över mer än det disponibla beloppet på kontot",
      newTransfer: "Gör en ny överföring",
      transferedAmount: "Överfört belopp",
      transferErrorHeader: "Något gick fel",
      transferErrorText: "Något gick fel. Försök igen senare",
      clearingNumberError: "Kontrollera att du angett korrekt clearingnummer.",
      clearingNumberFromUnsupportedBank: "Denna typ av konto accepteras inte.",
      accountNumberError: "Kontrollera att du angett korrekt kontonummer.",
      invalidTotalLengthError: "Du har fyllt i kontonummer på fel sätt, vänligen försök igen.",
      clearingStartsWithEightThenFiveDigitsError: "Clearingnummer som börjar på 8 har alltid 5 siffror",
      displayNameError: "Ange ett namn, högst 25 bokstäver.",
      duplicateAccount: "Kontonumret finns redan i din lista med sparade mottagare.",
      accountName: "Kontonamn",
    },
    loan: {
      totalSum: "Total summa",
      informationLabel: "Lånedetaljer",
      amount: "Lånebelopp",
      credit: "Saldo",
      coAppliant: "Medlåntagare",
      insurance: "Låneskydd",
      directDebit: "Autogiro",
      number: "Lånenummer",
      terminationAmountError: "Något gick fel. Försök igen senare",
      paymentInfo: "Betalningsinformation",
      getLoanInsurance: "Teckna låneskydd - en försäkring för ditt lån",
      technicalIssueTitle: "Tekniska problem",
      technicalIssueText: "Vi har just problem med att hämta vissa kontotyper. Prova gärna igen senare.",
      closedAccountText: "Privatlån är avslutade.",
      closedDateTitle: "Förfallodatum",
      closedAccountInfo: "Kontot är avslutat",
      maturityDateTitle: "Förfallodatum",
    },
    deposit: {
      accountDetailsLabel: "Kontouppgifter",
      accountBalanceLabel: "Saldo",
      availableAmountLabel: "Disponibelt Belopp",
      withdrawalTimeLabel: "Uttagstid",
      myDeposit6: "Mitt spar 6 (not in use)",
      myDeposit12: "Mitt spar 12 (not in use)",
      myDeposit24: "Mitt spar 24 (not in use)",
      myDeposit: "Mitt spar",
      myDepositBankingDays: "1-3 bankdagar",
      myDepositPlus: "Mitt spar+",
      myDepositPlusBankingDays: "31 dagar",
      interestFeesLabel: "Räntor och avgifter",
      accumulatedInterestLabel: "Intjänad ränta i år",
      freeOfChargeLabel: "Avgift",
      freeOfCharge: "Avgiftsfritt",
      transferAccount: "Uttagskonto",
      chooseTransferAccount: "Välj uttagskonto",
      chooseReceiverOrCreate: "Välj sparat konto eller ange nytt",
      newReceiver: "Ny mottagare",
      accountInformation: "Kontoinformation",
      accruedInterest: "Intjänad ränta",
      tax: "Skatt",
      totalTransferAmount: "Totalt att utbetala",
      closingDate: "Avslutsdatum",
      closeAccount: "Avsluta konto",
      receiverSuccessLabel: "Dina pengar kommer att betalas ut till",
      receiverAccount: "Uttagskonto",
      accountRedemptionErrorHeader: "Något gick fel",
      accountRedemptionErrorText: "Något gick fel. Försök igen senare",
      totalSavingAmount: "Totalt sparbelopp",
      FTDMaturityDateTemplateStringText: "<p><strong>Låst</strong> – Kontot är låst till och med: {maturityDate}</p>",
      FTDActivationDateTemplateStringText:
        "<p><strong>Aktiveras snart</strong> – Du kan lägga in pengar fram till och med: {activationDate}</p>",
      FTDMaturityDateTitle: "Förfallodatum",
      FTDActivationDateTitle: "Aktiveringsdatum",
      FTDDepositPageMaturityDateTemplateStringText:
        "<strong>Kontot är låst till och med {maturityDate}</strong><br>Dina pengar är låsta i X månader till en fast ränta (X%).",
      FTDDepositPageActivationDateTemplateStringText:
        "<strong>Du har {activationDaysLeftText} kvar att sätta in pengar</strong><br>Du kan fortfarande sätta in pengar på ditt konto fram till och med {activationDate}, efter det kommer kontot att låsas.",
      FTDDepositToAccountDisabledText:
        "Inga insättningar möjliga då aktiveringsperioden för det här kontot har utlöpt.",
      FTDDepositFromAccountDisabledText:
        "Aktiveringsperioden för det här kontot har utlöpt. Vill du ta ut pengarna innan bindningstiden är över vänligen kontakta kundtjänst.",
      FTDWithdrawalInfo:
        "Den fasta räntan är bunden till {maturityDate}, insättningar och uttag är låsta tills bindningstiden gått (/löpt) ut.",
      FTDActivationDaysLeftText: "{activationDaysLeft} dagar",
      FTDLastActivationDayText: "idag",
      closedAccountText: "Sparkontona är avslutade.",
      bindingPeriod: "Bindningstid",
      earlyDebitFeeRateLabel: "Uttagsavgift under bindningstid",
      fee: "Avgift",
      accountOpenDateLabel: "Startdatum",
      accountActivationDateLabel: "Bindningstiden startar",
      accountMaturityDateLabel: "Bindningstiden slutar",
      minimumInterestBearingAmountLabel: "Minsta räntebärande belopp",
      productType: "Kontotyp",
      accountNameDefault: "Sparkonto",
      accountSetNameErrorText: "Något gick fel. Försök igen senare",
      accountSetNameValidationErrorText:
        "Kontonamnet får endast innehålla bokstäver och siffror, inga specialtecken. Maxlängd på kontonamnet är 20 tecken.",
      closedDateLabel: "Förfallodatum",
      closedAccountInfo: "Kontot är avslutat",
    },
    leasing: {
      licensePlate: "Regnr",
      leaseInformationLabel: "Information om hyresavtalet",
      accountNumberLabel: "Kontonummer",
      expirationDateLabel: "Första förfallodagen för hyra",
      rentalPeriodLabel: "Hyrestid",
      dealerLabel: "Återförsäljare",
      modelLicenseLabel: "Modell och regnr",
      interestFeesLabel: "Räntor och avgifter",
      aviFeeLabel: "Aviavgift",
      closedAccountText: "Leasings är avslutade.",
      closedDateTitle: "Förfallodatum",
      closedAccountInfo: "Kontot är avslutat",
    },
    misc: {
      customer: "Kund",
      chooseProduct: "Välj produkt",
      chooseACard: "Välj kort",
      chooseEmploymentStatus: "Välj anställningsform",
      fee: "Avgift",
      interest: "Ränta",
      interestFees: "Räntor och avgifter",
      effectiveInterest: "Effektiv ränta",
      nominalInterest: "Nominell ränta",
      title: "Net Bank",
      logout: "Logga ut",
      published: "Publicerad",
      name: "Namn",
      accountNumber: "Kontonummer",
      accountName: "Kontonamn",
      balance: "Saldo",
      availableBalance: "Disponibelt saldo",
      availableAmount: "Disponibelt belopp",
      date: "Datum",
      purchaseDate: "Köpdatum",
      dateRange: "Datumintervall",
      foreignAmount: "Belopp i annan valuta",
      exchangeRate: "Valutakurs",
      reservedAmount: "Reserverat belopp",
      amount: "Belopp",
      receiver: "Mottagare",
      accountingDate: "Bokföringsdag",
      transactionDate: "Transaktionsdag",
      interestDate: "Bokföringsdag",
      paymentDate: "Betalningsdag",
      fromAccount: "Från konto",
      message: "Meddelande",
      messages: "Meddelanden",
      ocrMessage: "OCR-nummer/meddelande",
      ocrNumber: "OCR-nummer",
      youHave: "Du har",
      unreadMessage: "oläst meddelande",
      unreadMessages: "olästa meddelanden",
      unpaidInvoice: "obetald faktura",
      unpaidInvoices: "obetalda fakturor",
      and: "och",
      search: "Sök",
      cancel: "Avbryt",
      apply: "Tillämpa",
      applyFor: "Ansök",
      amountFrom: "Från belopp",
      amountTo: "Till belopp",
      dateFrom: "Datum från",
      dateTo: "Datum till",
      selectDate: "Välj datum",
      clear: "Rensa",
      clearFilter: "Rensa filtret",
      select: "Välj",
      email: "E-post",
      mobile: "Mobilnummer",
      phone: "Telefonnummer",
      nameAdress: "Namn & adress",
      sms: "SMS",
      internetbank: "Internetbank",
      saved: "Sparad",
      updateInfo: "Uppdatera",
      downloadInfo: "Ladda ner",
      licensePlate: "Registreringsnummer",
      fetchMore: "Hämta fler",
      showLess: "Visa färre",
      yes: "Ja",
      no: "Nej",
      invoiceFee: "Aviavgift",
      bankTransferFee: "Kostnad banköverföring",
      atmFee: "Kostnad bankomatuttag",
      currencyExchangeFee: "Kostnad valutaväxling",
      status: "Status",
      active: "Aktivt",
      inactive: "Inaktiv",
      ssn: "Personnummer",
      storeName: "Beskrivning",
      atLeast: "Minst",
      upTo: "Upp till",
      exists: "Finns",
      missing: "Finns ej",
      blocked: "Spärrat",
      notActive: "Ej aktiverat",
      other: "Annat",
      send: "Skicka",
      delete: "Radera",
      save: "Spara",
      close: "Stäng",
      edit: "Ändra",
      seeTerms: "Se villkoren",
      saveAndUse: "Spara och använd",
      summary: "Sammanställning",
      terminate: "Avsluta",
      continue: "Fortsätt",
      years: "år",
      monthShort: "mån",
      with: "med",
      contractNumber: "Kontraktsnummer",
      directDebit: "Autogiro",
      document: "Dokument",
      headline: "Rubrik",
      done: "Klar",
      chooseDifferentDate: "Välj ett annat datum",
      calculate: "Räkna ut",
      termsButtonText: "Jag har läst och förstått villkoren",
      order: "Beställ",
      doesNotExist: "Finns ej",
      accountInformation: "Kontoinformation",
      accountDetails: "Kontouppgifter",
      nameAndDate: "Namn / datum",
      exportXLSX: "Exportera till kalkylblad (xlsx)",
      reserved: "Reserverad",
      outgoing: "På väg ut",
      multiSelectNoOptions: "Inga träffar",
      chooseCitizenshipPlaceholder: "Börja skriva, välj land i listan",
      newMonthlyCost: "Ny månadskostnad",
      newNumberOfTerms: "Ny återbetalningstid",
      newLoanBalance: "Nytt lånebelopp",
      somethingWentWrong: "Något gick fel",
      previous: "Föregående",
      next: "Nästa",
      sign: "Signera",
      questions: "Frågor",
      completed: "avklarat",
      onwards: "Fortsätt",
      back: "Tillbaka",
      information: "Information",
      organizationNumber: "Organisationsnummer",
      organizationName: "Företag",
    },
    file: {
      desktopLabel: "Släpp filer här eller ",
      desktopLabelSuffix: "ladda upp",
      mobileLabel: "Ladda upp filer här",
      maxFileSizeLabel: "Max filstorlek",
      maxCombinedFileSizeLabel:
        "Den kombinerade filstorleken överstiger {{- limit}}. Vänligen minska antalet filer eller välj mindre filer.",
      maxAttachmentsError: "Maximalt tillåtna antal bilagor är {{- count}}.",
      acceptedExtensionsLabel: "Tillåtna filformat",
      acceptedExtensions: "{{- extensions, to_sentence}}",
      attachmentValidationError:
        "Uppladdning misslyckades. Undvik duplicerade filnamn och se till att endast använda {{- extensions, to_sentence}} filer. Varje fil får inte överstiga 10 MB.",
      duplicateAttachmentOrSizeError:
        "Uppladdning misslyckades. En eller flera av de valda filerna är redan uppladdade. Varje fil får inte överstiga 10 MB.",
      singleFileAttachmentError:
        "Något gick fel vid uppladdning av {{- fileName}}. Kontrollera filformat samt filstorlek och försök igen.",
    },
    date: {
      periodOfTime: "Tidsperiod",
      last30: "Senaste 30 dagarna",
      last3Months: "Senaste 3 månaderna",
      thisYear: "Hittills i år",
      months: "månader",
      validFrom: "Gäller från",
    },
    emptyState: {
      header: "Inga resultat hittades!",
      text: "Försök igen senare!",
    },
    login: {
      header: "Logga in",
      text: "I Interbetbanken gör du som privatkund enkelt dina bankärenden när du vill.",
      updateRequired: "Du måste ladda ner den senaste versionen av appen för att kunna logga in.",
      placeholder: "Ange personnummer",
      loginButtonTextMobile: "Mobilt BankID",
      loginButtonTextDesktop: "BankID på denna enhet",
      loginMobileBankId: "Logga in med Mobilt BankID",
      waitingForMobileBankID: "Väntar på svar från svar från Mobilt BankID",
      rememberMe: "Kom ihåg mig",
      mockData: "Mockdata",
      loginQRTitle: "Scanna QR-kod med BankID appen",
      verifyingTextDesktop: "Skriv in din säkerhetskod i BankID-appen. Klicka här om du behöver hjälp.",
      verifyingTextMobile: "För att logga in, starta din BankID applikation på din mobil.",
      bankIDLinkName: "här",
      santanderHomepageURL: "https://www.santanderconsumer.se",
      profilePickerHeader: "Välj profil",
      profilePickerSubHeader: "Välj vilken profil du vill logga in med",
      privateProfileLabel: "Privat",
      organizationProfileLabel: "Företag",
    },
    message: {
      inbox: "Inkorg",
      archive: "Arkiv",
      toArchive: "Arkivera",
      selectAccountText: "Välj konto",
      selectSubjectText: "Välj ämne",
      selectAreaText: "Välj område",
      accountLabelText: "Välj ditt konto",
      subjectLabelText: "Välj ett ämne",
      areaLabelText: "Välj ett område",
      composeNewMessage: "Skriv nytt meddelande",
      newMessage: "Nytt meddelande",
      sendMessage: "Skicka meddelande",
      textareaPlaceholder: "Skriv ditt meddelande här",
      textareaReplyPlaceholder: "Klicka för att skriva ditt svar",
      attach: "Bilaga",
      faq: "Vanliga frågor",
      loadMore: "Se fler mail",
      reply: "Svara",
      prevDialog: "Tidigare dialog",
      backToInbox: "Tillbaka till inboxen",
      inboxEmpty: "Din inkorg är tom",
      productError: "Välj ett konto",
      subjectError: "Välj ett ämne",
      areaError: "Välj ett område",
      messageError: "Ange ett meddelande",
      goBack: "Gå tillbaka",
      sent: "Skickat",
    },
    offers: {
      PPIC: "Kreditskydd",
      PFMC: "Betalningsfri månad",
      PFML: "Betalningsfri månad",
      LINC: "Kredithöjning",
      PPIL: "Betalskydd",
      AOL: "Höja lån",
    },
    topUp: {
      header: "Höja lån",
      mainApplicantHeader: "Dina uppgifter",
      coApplicantHeader: "Medsökande",
      loanAmountLabel: "Lånebelopp",
      repaymentTimeLabel: "Återbetalningstid",
      monthlyCostLabel: "Månadskostnad",
      currentLoanAmountLabel: "Lånebelopp innan höjning",
      loanAmountIncreaseLabel: "Summa höjning",
      newLoanAmountLabel: "Nytt lånebelopp",
      sharedAccomodationLabel: "Delar du hushåll med huvudsökande?",
      creditCheckSliderText: "En kreditupplysning kommer att göras.",
      creditCheckSummaryText:
        "Eftersom du har valt ett belopp som är högre än det utlovade beloppet kommer en kreditupplysning behöva göras",
      monthlyIncomeBeforeTaxLabel: "Månadsinkomst (före skatt)",
      monthlyIncomeLabel: "Månadsinkomst",
      employmentStatusLabel: "Anställningsform",
      emailLabel: "E-post",
      phoneLabel: "Telefonnummer",
      employerLabel: "Nuvarande arbetsgivare",
      employedFromLabel: "Anställd från och med år och månad",
      fromLabel: "Från och med",
      toLabel: "Till och med",
      unEmployedFromLabel: "Arbetsökande sedan",
      employedToLabel: "Anställd till och med år och månad",
      housingCostLabel: "Boendekostnad",
      totalSizeOfAllDebtLabel: "Total skuld på lån",
      totalCostOfAllDebtLabel: "Månadskostnad för samtliga lån",
      numberOfChildrenLabel: "Antal barn som bor hemma",
      loansMonthlyCostLabel: "Månadskostnad för lån (ej bolån)",
      citizenShipsLabel: "Medborgarskap",
      loanInsuranceHeader: "Teckna Låneskydd för extra trygghet",
      mainApplicantSummaryHeader: "Huvudlåntagarens uppgifter",
      coApplicantSummaryHeader: "Medlåntagares uppgifter",
      approveAndSign: "Godkänn och signera",
      payoutAccountLabel: "Utbetalningskonto",
      choosePayoutAccount: "Välj utbetalningskonto",
      employmentStatus: {
        PermanentPosition: "Tillsvidareanställning",
        TemporaryPosition: "Vikariat",
        SelfEmployed: "Egenföretagare",
        Student: "Student",
        Retired: "Pensionär",
        Unemployed: "Arbetssökande",
      },
      sourceOfFundsSalaryLabel: "Jag kommer återbetala lånet med min huvudsakliga inkomst",
      sourceOfFundsLabel: "Var kommer huvudsakligen pengarna ifrån som ska användas för att återbetala lånet?",
      customSourceOfFundsPlaceholder: "Ange källa för återbetalning",
      purposeOfLoanLabel: "Jag vill låna för att",
      customPurposeOfLoanPlaceholder: "Pengarna kommer användas till",
      sourceOfFunds: {
        Salary: "Lön",
        Pension: "Pension",
        Savings: "Sparande",
        SocialBenefits: "Socialbidrag",
        CommercialIncome: "Intäkter från näringsverksamhet inklusive hyresintäkter",
        SaleOfRealEstate: "Vinst från försäljning av fastighet",
        SaleOfMovables: "Vinst från försäljning av lös egendom (bil, båt, etc.)",
        InheritanceGifts: "Arv/gåvor",
        YieldInvestments: "Vinst från investeringar",
        ShareDividend: "Aktieutdelning eller liknande vinst från näringsverksamhet",
        Compensation: "Annan typ av ersättning (t.ex. från försäkring, avgångsvederlag eller liknande)",
        Gambling: "Vinster från spel/lotterier",
        TheOtherDebtorWillRepayLoan: "Den andra låntagaren återbetalar lånet",
        EducationalSupport: "Studiestöd",
        Other: "Annat",
      },
      purposeOfLoan: {
        TravelOrLeisure: "Resor eller fritid",
        RefinanceRepayOtherLoansCredit: "Lösa andra lån eller krediter",
        RenovationOfHouseCabinOrOtherProperty: "Renovering",
        HealthExpensesDentistEtc: "Hälsorelaterade utgifter",
        MoveablesVehicleOrRelatedExpenses: "Fordon",
        EducationOrProfessionalTraining: "Utbildning",
        WeddingOrFamilyOccasion: "Bröllop eller familjetillfällen",
        ToCoverForPeriodOfUnemployment: "För att täcka en period av arbetslöshet",
        RelocationFurnishingSecurityDeposit: "Kostnad för flytt eller inredning",
        Other: "Annat",
      },
      housingTypeLabel: "Boendetyp",
      housingType: {
        None: "",
        Condominium: "Bostadsrätt",
        OwnedHouse: "Boende i eget hus/egen fastighet",
        Tenancy: "Hyresrätt via ett förstahandskontrakt",
        SecondHandApartment: "Hyresrätt via ett andrahandskontrakt",
        CollectiveHousing: "Boende i ett kollektiv (delat boende)",
      },
      unsuccessfulTopUpHeader: "Något gick fel",
      finish: "Avsluta",
      choosePayoutAccountHeader: "Ange utbetalningskonto",
      pepLabel: "Person i politisk ställning (PEP)",
      isPersonallyApplyingLabel: "Söka för annan part",
      isPersonallyApplyingCheckboxText: "Jag bekräftar att jag inte ansöker om lån för annans räkning.",
      isPersonallyApplyingErrorText:
        "Vi kan inte fortsätta med din ansökan om det är så att du ansöker åt någon annan än dig själv.",
      approveCreditCheckLabel: "Jag godkänner att Santander gör en kreditprövning (UC).",
      tooManyCitizenshipsError: "Du kan som mest ange 5 medborgarskap.",
      processingHeader: "Tack!",
      processingText: "Vi bearbetar ansökan just nu. Var god vänta.",
    },
    pendingApplications: {
      bannerHeader: "Du har en oavslutat låneansökan.",
      bannerContent: "<p>För att återuppta din låneansöka klicka på knappen nedan</p>",
      disbursementBannerHeader: "Din höjning är klar!",
      disbursementBannerContent: "<p>Pengarna kommer finnas på ditt bankkonto inom 1–3 bankdagar.</p>",
      bannerButtonText: "Återuppta låneansökan",
      resumeTopUpText: "Du har en påbörjad lånehöjning på detta konto. Klicka ",
      resumeTopUpLinkText: "här för att återuppta",
    },
    placeholders: {
      chooseAccount: "Välj konto",
      year: "ÅÅÅÅ",
      month: "MM",
    },
    error: {
      required: "Fältet är obligatoriskt.",
      incorrectEmail: "Något blev fel. Titta efter så du fyllt i din e-postadress rätt.",
      conflictingEmail: "Vänligen ange en annan e-postadress.",
      tooShortPhone: "Mobilnummer måste vara minst 8 siffror.",
      invalidPhone: "Ogiltig telefonnummer.",
      tooLongPhone: "Mobilnummer får vara max 13 siffror.",
      conflictingPhone: "Vänligen ange ett annat telefonnummer.",
    },
    letters: {
      yearlyStatement: "Räntebesked",
    },
    retryPage: {
      title: "Oj.",
      text: "Någonting gick fel. Prova att ladda om sidan.",
      reloadLink: "Ladda om",
    },
    langPicker: {
      label: "Välj språk",
      sv: "svenska",
      en: "English",
    },
    compliance: {
      bannerButton: "Till frågorna",
      validationErrorText: "Det uppstod ett oväntat fel. Kontrollera att alla svar är ifyllda.",
      unexpectedErrorText: "Något gick fel. Försök igen senare",
      processingHeader: "Tack!",
      processingText: "Vi bearbetar din ansökan just nu. Var god vänta.",
    },
  },
};

import dayjs from "dayjs";
import { AccountStatus } from "../../../../models/Engagements/Account";
import { CardStatus, IEngagements } from "../../../../models/Engagements";
import { AccountType, DepositType } from "../../../../models/Content/Enums";

export const mockEngagements: IEngagements = {
  creditAccounts: [
    {
      accountStatus: AccountStatus.AccountToClose,
      availableBalance: 0.0,
      authorizedBalance: 0.0,
      coApplicantName: undefined,
      hasDirectDebit: true,
      paymentAccount: "5562-1734",
      hasInsurance: false,
      insurances: undefined,
      maximumInterestBearingAmount: 0.0,
      cards: [],
      atmFee: "35 Kr",
      bankTransferFee: undefined,
      currencyExchangeFee: undefined,
      displayNumber: "2-3151256593",
      accountNumber: "23151256593",
      accountType: AccountType.Credit,
      canOrderCard: true,
      applicationReceived: true,
      bookedBalance: -6844.0,
      accountInterest: 14.95,
      effectiveInterest: 20.45,
      creditLimit: 7000.0,
      chainName: "53",
      chainNumber: 53,
      storeNumber: "0971051",
      invoiceFee: 30.0,
      nameIB: "Mitt kort trygga köp",
      name: "Mitt kort trygga köp",
      bankTransferMinimumFee: 30,
      bankTransferPercentageFee: 3,
      accountPersonalName: "My favourite card",
    },
    {
      accountStatus: AccountStatus.AccountOk,
      availableBalance: 306.91,
      authorizedBalance: 1.0,
      coApplicantName: undefined,
      hasDirectDebit: false,
      paymentAccount: "5562-1734",
      hasInsurance: false,
      insurances: undefined,
      maximumInterestBearingAmount: 0.0,
      cards: [],
      atmFee: "40 Kr",
      bankTransferFee: undefined,
      currencyExchangeFee: undefined,
      displayNumber: "3-3125380791",
      accountNumber: "33125380791",
      accountType: AccountType.Credit,
      canOrderCard: true,
      applicationReceived: true,
      bookedBalance: -14692.09,
      accountInterest: 16.05,
      effectiveInterest: 17.45,
      creditLimit: 15000.0,
      chainName: "Mediamarkt",
      chainNumber: 809,
      storeNumber: "0957985",
      invoiceFee: 30.0,
      nameIB: "Butikskredit",
      name: "Mediamarkt",
      bankTransferMinimumFee: 30,
      bankTransferPercentageFee: 3,
    },
    {
      accountStatus: AccountStatus.AccountOk,
      availableBalance: 32000.0,
      authorizedBalance: 0.0,
      coApplicantName: undefined,
      hasDirectDebit: false,
      paymentAccount: "5562-1734",
      hasInsurance: false,
      insurances: undefined,
      maximumInterestBearingAmount: 0.0,
      cards: [
        {
          accountNumber: "93530002743298",
          cardId: "353000705864010",
          isManufactured: true,
          annualFee: 0.0,
          displayNumber: "420857XXXXXX7057",
          status: CardStatus.Active,
          isVirtual: true,
          cardType: 29,
          cardTypeName: undefined,
          expireDate: new Date("2025-11-01T00:00:00+01:00"),
          embossingDate: undefined,
          embossingName: undefined,
          insuranceType: undefined,
          isSecureCodeRegistered: false,
          isActive: false,
        },
      ],
      atmFee: undefined,
      bankTransferFee: undefined,
      currencyExchangeFee: undefined,
      displayNumber: "93530002743298",
      accountNumber: "93530002743298",
      accountType: AccountType.Credit,
      bookedBalance: 0.0,
      accountInterest: 0.0,
      effectiveInterest: 0.0,
      creditLimit: 32000.0,
      chainName: "136000000",
      chainNumber: 136000000,
      storeNumber: "136001001",
      invoiceFee: 0.0,
      nameIB: "Mitt Kort",
      name: "Mitt Kort",
      canOrderCard: true,
      bankTransferMinimumFee: 35,
      bankTransferPercentageFee: 3,
    },
    {
      accountStatus: AccountStatus.AccountOk,
      availableBalance: 32000.0,
      authorizedBalance: 0.0,
      coApplicantName: undefined,
      hasDirectDebit: false,
      paymentAccount: "5562-1734",
      hasInsurance: false,
      insurances: undefined,
      maximumInterestBearingAmount: 0.0,
      cards: [
        {
          accountNumber: "93530002743297",
          cardId: "353000705864012",
          isManufactured: false,
          annualFee: 0.0,
          displayNumber: "420857XXXXXX7052",
          status: CardStatus.Inactive,
          cardType: 29,
          cardTypeName: undefined,
          expireDate: new Date("2025-11-01T00:00:00+01:00"),
          embossingDate: undefined,
          embossingName: undefined,
          insuranceType: undefined,
          isSecureCodeRegistered: false,
          isActive: false,
        },
      ],
      atmFee: undefined,
      bankTransferFee: undefined,
      currencyExchangeFee: undefined,
      displayNumber: "93530002743297",
      accountNumber: "93530002743297",
      accountType: AccountType.Credit,
      bookedBalance: 0.0,
      accountInterest: 0.0,
      effectiveInterest: 0.0,
      creditLimit: 32000.0,
      chainName: "136000000",
      chainNumber: 136000000,
      storeNumber: "136001001",
      invoiceFee: 0.0,
      nameIB: "Mitt kort (Ej tillverkad)",
      name: "Mitt kort (Ej tillverkad)",
      canOrderCard: true,
      bankTransferMinimumFee: 35,
      bankTransferPercentageFee: 3,
    },
    {
      accountStatus: AccountStatus.AccountOk,
      availableBalance: 32000.0,
      authorizedBalance: 0.0,
      coApplicantName: undefined,
      hasDirectDebit: false,
      paymentAccount: "5562-1734",
      hasInsurance: false,
      insurances: undefined,
      maximumInterestBearingAmount: 0.0,
      cards: [
        {
          accountNumber: "93530002745631",
          cardId: "353000705865826",
          annualFee: 0.0,
          displayNumber: "420857XXXXXX3148",
          status: CardStatus.Active,
          cardType: 29,
          cardTypeName: undefined,
          expireDate: new Date("2025-11-01T00:00:00+01:00"),
          embossingDate: undefined,
          embossingName: undefined,
          insuranceType: undefined,
          isSecureCodeRegistered: false,
          isActive: true,
        },
      ],
      atmFee: undefined,
      bankTransferFee: undefined,
      currencyExchangeFee: undefined,
      displayNumber: "93530002745631",
      accountNumber: "93530002745631",
      accountType: AccountType.Credit,
      bookedBalance: 0.0,
      accountInterest: 0.0,
      effectiveInterest: 0.0,
      creditLimit: 32000.0,
      chainName: "136000000",
      chainNumber: 136000000,
      storeNumber: "136001001",
      invoiceFee: 0.0,
      nameIB: "Mitt Kort",
      name: "Mitt Kort",
      canOrderCard: true,
      bankTransferMinimumFee: 35,
      bankTransferPercentageFee: 3,
      isDisabled: true,
    },
  ],
  privateLoanAccounts: [
    {
      coApplicantName: "Anna Andersson",
      effectiveInterest: 0.0,
      hasCoApplicant: true,
      hasDirectDebit: true,
      hasInsurance: false,
      insurances: null,
      displayNumber: "4257557",
      accountNumber: "628302",
      accountType: AccountType.PrivateLoan,
      bookedBalance: -68141.0,
      accountInterest: 7.85,
      creditLimit: 127000.0,
      chainName: null,
      chainNumber: 0,
      storeNumber: null,
      invoiceFee: 0.0,
      nameIB: "Mitt lån",
      paymentAccount: "5562-1734",
      canCalculateTerminationAmount: true,
      accountPersonalName: "Renovation loan",
      loanMaturityDate: "2030-12-22",
    },
    {
      coApplicantName: null,
      effectiveInterest: 0.0,
      hasCoApplicant: false,
      hasDirectDebit: true,
      hasInsurance: true,
      insurances: null,
      displayNumber: "4-354176",
      accountNumber: "653405",
      accountType: AccountType.PrivateLoan,
      bookedBalance: -85732.0,
      accountInterest: 7.85,
      creditLimit: 149000.0,
      chainName: null,
      chainNumber: 0,
      storeNumber: null,
      invoiceFee: 0.0,
      nameIB: "Mitt lån",
      paymentAccount: "5562-1734",
      canCalculateTerminationAmount: true,
      loanMaturityDate: "2030-12-22",
    },
    {
      coApplicantName: null,
      effectiveInterest: 0.0,
      hasCoApplicant: false,
      hasDirectDebit: true,
      hasInsurance: true,
      insurances: null,
      displayNumber: "4-354199",
      accountNumber: "653499",
      accountType: AccountType.PrivateLoan,
      bookedBalance: -85732.0,
      accountInterest: 7.85,
      creditLimit: 149000.0,
      chainName: null,
      chainNumber: 0,
      storeNumber: null,
      invoiceFee: 0.0,
      nameIB: "Mitt lån",
      paymentAccount: "5562-1799",
      canCalculateTerminationAmount: true,
      isDisabled: true,
      loanMaturityDate: "2030-12-22",
    },
    {
      coApplicantName: "Anna Andersson",
      effectiveInterest: 0.0,
      hasCoApplicant: true,
      hasDirectDebit: true,
      hasInsurance: false,
      insurances: null,
      displayNumber: "42575570",
      accountNumber: "6283020",
      accountType: AccountType.PrivateLoan,
      bookedBalance: -100.0,
      accountInterest: 7.85,
      creditLimit: 127000.0,
      chainName: null,
      chainNumber: 0,
      storeNumber: null,
      invoiceFee: 0.0,
      nameIB: "Mitt lån",
      paymentAccount: "5562-1734",
      canCalculateTerminationAmount: true,
      accountPersonalName: "Renovation loan",
      closeDate: "2024-05-05",
      accountStatus: AccountStatus.AccountClosed,
      isDisabled: true,
      loanMaturityDate: "2030-12-22",
    },
    {
      coApplicantName: "Anna Andersson",
      effectiveInterest: 0.0,
      hasCoApplicant: true,
      hasDirectDebit: true,
      hasInsurance: false,
      insurances: null,
      displayNumber: "4257559",
      accountNumber: "6283029",
      accountType: AccountType.PrivateLoan,
      bookedBalance: -5.0,
      accountInterest: 7.85,
      creditLimit: 127000.0,
      chainName: null,
      chainNumber: 0,
      storeNumber: null,
      invoiceFee: 0.0,
      nameIB: "Mitt lån",
      paymentAccount: "5562-1734",
      canCalculateTerminationAmount: true,
      closeDate: "2024-05-01",
      accountStatus: AccountStatus.AccountClosed,
      loanMaturityDate: "2030-12-22",
    },
    {
      coApplicantName: "Anna Andersson",
      effectiveInterest: 0.0,
      hasCoApplicant: true,
      hasDirectDebit: true,
      hasInsurance: false,
      insurances: null,
      displayNumber: "7257559",
      accountNumber: "7283029",
      accountType: AccountType.PrivateLoan,
      bookedBalance: -15.0,
      accountInterest: 7.85,
      creditLimit: 127000.0,
      chainName: null,
      chainNumber: 0,
      storeNumber: null,
      invoiceFee: 0.0,
      nameIB: "Mitt lån",
      paymentAccount: "5562-1734",
      canCalculateTerminationAmount: true,
      closeDate: "2024-05-01",
      accountStatus: AccountStatus.AccountClosed,
      loanMaturityDate: "2030-12-22",
    },
  ],
  securedLoanAccounts: [
    {
      accountInterest: 5.35,
      accountNumber: "969158",
      accountType: AccountType.SecuredLoan,
      bookedBalance: -128183,
      chainName: null,
      chainNumber: 0,
      coApplicantName: null,
      creditLimit: 187000,
      displayNumber: "1061999",
      effectiveInterest: 0,
      hasCoApplicant: false,
      hasDirectDebit: false,
      hasInsurance: false,
      insurances: null,
      invoiceFee: 55,
      nameIB: "Avbetalning",
      paymentAccount: "5562-1734",
      storeNumber: null,
      canCalculateTerminationAmount: true,
      accountPersonalName: "My loan",
      hasInsurance: true,
      loanMaturityDate: "2030-12-22",
      hasEiopaInsurance: false,
    },
    {
      accountInterest: 5.35,
      accountNumber: "669158",
      accountType: AccountType.SecuredLoan,
      bookedBalance: -1,
      chainName: null,
      chainNumber: 0,
      coApplicantName: null,
      creditLimit: 187000,
      displayNumber: "3061999",
      effectiveInterest: 0,
      hasCoApplicant: false,
      hasDirectDebit: false,
      hasInsurance: false,
      insurances: null,
      invoiceFee: 55,
      nameIB: "Avbetalning",
      paymentAccount: "5562-1734",
      storeNumber: null,
      canCalculateTerminationAmount: true,
      accountPersonalName: "My loan2",
      hasInsurance: true,
      loanMaturityDate: "2030-12-22",
      hasEiopaInsurance: true,
    },
    {
      accountInterest: 5.35,
      accountNumber: "669199",
      accountType: AccountType.SecuredLoan,
      bookedBalance: -128183,
      chainName: null,
      chainNumber: 0,
      coApplicantName: null,
      creditLimit: 187000,
      displayNumber: "1061900",
      effectiveInterest: 0,
      hasCoApplicant: false,
      hasDirectDebit: false,
      hasInsurance: false,
      insurances: null,
      invoiceFee: 55,
      nameIB: "Avbetalning",
      paymentAccount: "5562-1799",
      storeNumber: null,
      canCalculateTerminationAmount: true,
      isDisabled: true,
      loanMaturityDate: "2030-12-22",
    },
    {
      accountInterest: 5.35,
      accountNumber: "769158",
      accountType: AccountType.SecuredLoan,
      bookedBalance: -2,
      chainName: null,
      chainNumber: 0,
      coApplicantName: null,
      creditLimit: 187000,
      displayNumber: "2061999",
      effectiveInterest: 0,
      hasCoApplicant: false,
      hasDirectDebit: false,
      hasInsurance: false,
      insurances: null,
      invoiceFee: 55,
      nameIB: "Avbetalning",
      paymentAccount: "5562-1111",
      storeNumber: null,
      canCalculateTerminationAmount: true,
      accountPersonalName: "My loan",
      accountStatus: AccountStatus.AccountClosed,
      closeDate: "2024-01-01",
      loanMaturityDate: "2030-12-22",
    },
    {
      accountInterest: 5.35,
      accountNumber: "769239",
      accountType: AccountType.SecuredLoan,
      bookedBalance: -1,
      chainName: null,
      chainNumber: 0,
      coApplicantName: null,
      creditLimit: 187000,
      displayNumber: "2061900",
      effectiveInterest: 0,
      hasCoApplicant: false,
      hasDirectDebit: false,
      hasInsurance: false,
      insurances: null,
      invoiceFee: 55,
      nameIB: "Avbetalning",
      paymentAccount: "5562-2222",
      storeNumber: null,
      canCalculateTerminationAmount: true,
      isDisabled: true,
      accountStatus: AccountStatus.AccountClosed,
      closeDate: "2024-03-03",
      loanMaturityDate: "2030-12-22",
    },
    {
      accountInterest: 5.35,
      accountNumber: "769199",
      accountType: AccountType.SecuredLoan,
      bookedBalance: -1,
      chainName: null,
      chainNumber: 0,
      coApplicantName: null,
      creditLimit: 187000,
      displayNumber: "2961900",
      effectiveInterest: 0,
      hasCoApplicant: false,
      hasDirectDebit: false,
      hasInsurance: false,
      insurances: null,
      invoiceFee: 55,
      nameIB: "Avbetalning",
      paymentAccount: "5562-2222",
      storeNumber: null,
      canCalculateTerminationAmount: true,
      accountStatus: AccountStatus.AccountClosed,
      closeDate: "2024-03-03",
      loanMaturityDate: "2030-12-22",
    },
  ],
  depositAccounts: [
    {
      openDate: dayjs().toString(),
      accountInterest: 0.0,
      accountNumber: "1687183",
      accountStatus: AccountStatus.AccountOk,
      accountType: AccountType.Deposit,
      depositType: DepositType.FTD6,
      accumulatedInterest: 0,
      atmFee: undefined,
      authorizedBalance: 0,
      availableBalance: 0.0,
      bankTransferFee: undefined,
      bookedBalance: 0.0,
      cards: [],
      chainName: undefined,
      chainNumber: undefined,
      closesAt: undefined,
      ...(() => {
        const today = dayjs().startOf("day");
        const daysPassed = Math.floor(Math.random() * 28);
        const activationDate = today.add(14 - daysPassed, "days").toISOString();
        const maturityDate = today
          .add(6, "months")
          .add(14 - daysPassed, "days")
          .toISOString();
        return {
          activationDate,
          maturityDate,
          isActivationPeriodExpired: today.isAfter(activationDate),
          isMatured: today.isAfter(maturityDate),
        };
      })(),
      creditLimit: 0,
      currencyExchangeFee: undefined,
      displayNumber: "300115167823",
      invoiceFee: 0.0,
      isActive: true,
      isDisabled: false,
      maximumInterestBearingAmount: undefined,
      minimumInterestBearingAmount: 25000,
      nameIB: "Mitt spar 6 (not in use)",
      paymentAccount: "5562-1734",
      storeNumber: undefined,
      earlyDebitFeeRate: 2.5,
      isFixedTerm: true,
      fixedTermMonths: 6,
    },
    {
      accountInterest: 0.0,
      accountNumber: "1687185",
      accountStatus: AccountStatus.AccountOk,
      accountType: AccountType.Deposit,
      depositType: DepositType.FTD12,
      accumulatedInterest: 0,
      atmFee: undefined,
      authorizedBalance: 0,
      availableBalance: 0.0,
      bankTransferFee: undefined,
      bookedBalance: 0.0,
      cards: [],
      chainName: undefined,
      chainNumber: undefined,
      closesAt: undefined,
      ...(() => {
        const today = dayjs();
        const daysPassed = Math.floor(Math.random() * 28);
        const activationDate = today.add(14 - daysPassed, "days").toISOString();
        const maturityDate = today
          .add(6, "months")
          .add(14 - daysPassed, "days")
          .toISOString();
        return {
          activationDate,
          maturityDate,
          isActivationPeriodExpired: today.isAfter(activationDate),
          isMatured: today.isAfter(maturityDate),
        };
      })(),
      creditLimit: 0,
      currencyExchangeFee: undefined,
      displayNumber: "310115167826",
      invoiceFee: 0.0,
      isActive: true,
      isDisabled: false,
      maximumInterestBearingAmount: undefined,
      minimumInterestBearingAmount: 100000,
      nameIB: "Mitt spar 12 (not in use)",
      paymentAccount: "5562-1734",
      storeNumber: undefined,
      earlyDebitFeeRate: 4.3,
      isFixedTerm: true,
      fixedTermMonths: 12,
    },
    {
      availableBalance: 1.0,
      authorizedBalance: 0.0,
      maximumInterestBearingAmount: 0.0,
      minimumInterestBearingAmount: 0.0,
      accumulatedInterest: 0.0,
      cards: [],
      atmFee: undefined,
      bankTransferFee: undefined,
      currencyExchangeFee: undefined,
      displayNumber: "200102246820",
      accountNumber: "442326",
      accountType: AccountType.Deposit,
      depositType: DepositType.MyDeposit,
      bookedBalance: 1.0,
      accountInterest: 0.95,
      creditLimit: 0.0,
      chainName: undefined,
      chainNumber: 0,
      storeNumber: undefined,
      invoiceFee: 0.0,
      nameIB: "Mitt spar",
      paymentAccount: "156-2461",
      isActive: true,
      closesAt: undefined,
      hasDirectDebit: true,
      isFixedTerm: false,
      accountPersonalName: "Travel savings",
    },
    {
      availableBalance: 66000.0,
      authorizedBalance: 0.0,
      maximumInterestBearingAmount: 0.0,
      minimumInterestBearingAmount: 0.0,
      accumulatedInterest: 194.41,
      cards: [],
      atmFee: undefined,
      bankTransferFee: undefined,
      currencyExchangeFee: undefined,
      displayNumber: "200102353428",
      accountNumber: "443739",
      accountType: AccountType.Deposit,
      depositType: DepositType.MyDeposit,
      bookedBalance: 66000.0,
      accountInterest: 0.95,
      creditLimit: 0.0,
      chainName: undefined,
      chainNumber: 0,
      storeNumber: undefined,
      invoiceFee: 0.0,
      nameIB: "Mitt spar",
      paymentAccount: "156-2461",
      isActive: true,
      closesAt: dayjs().add(1, "month").toISOString(),
      hasDirectDebit: false,
      isFixedTerm: false,
      accountPersonalName: "Very long account name over 20 characters",
    },
    {
      availableBalance: 0.0,
      authorizedBalance: 0.0,
      maximumInterestBearingAmount: undefined,
      accumulatedInterest: 0,
      cards: [],
      atmFee: undefined,
      bankTransferFee: undefined,
      currencyExchangeFee: undefined,
      closesAt: undefined,
      displayNumber: "201000001226",
      accountNumber: "1768617",
      accountStatus: AccountStatus.AccountClosed,
      accountType: AccountType.Deposit,
      depositType: DepositType.MyDeposit,
      bookedBalance: 55550.0,
      accountInterest: 0.95,
      creditLimit: 0.0,
      chainName: undefined,
      chainNumber: 0,
      storeNumber: undefined,
      invoiceFee: 0.0,
      nameIB: "Mitt spar",
      paymentAccount: "156-2461",
      isActive: true,
      hasDirectDebit: false,
      isFixedTerm: false,
      closeDate: "2023-01-01T00:00:00",
    },
    {
      availableBalance: 0.0,
      authorizedBalance: 0.0,
      maximumInterestBearingAmount: undefined,
      accumulatedInterest: 0,
      cards: [],
      atmFee: undefined,
      bankTransferFee: undefined,
      currencyExchangeFee: undefined,
      closesAt: undefined,
      displayNumber: "201000001228",
      accountNumber: "1768618",
      accountStatus: AccountStatus.AccountClosed,
      accountType: AccountType.Deposit,
      depositType: DepositType.MyDeposit,
      bookedBalance: 55551.0,
      accountInterest: 0.95,
      creditLimit: 0.0,
      chainName: undefined,
      chainNumber: 0,
      storeNumber: undefined,
      invoiceFee: 0.0,
      nameIB: "Mitt spar",
      paymentAccount: "156-2462",
      isActive: true,
      hasDirectDebit: false,
      isFixedTerm: false,
      closeDate: "2011-11-01T00:00:00",
    },
    {
      availableBalance: 3000.0,
      authorizedBalance: 0.0,
      maximumInterestBearingAmount: 0.0,
      minimumInterestBearingAmount: 0.0,
      accumulatedInterest: 1.41,
      cards: [],
      atmFee: undefined,
      bankTransferFee: undefined,
      currencyExchangeFee: undefined,
      displayNumber: "100005249022",
      accountNumber: "1675062",
      accountType: AccountType.Deposit,
      depositType: DepositType.MyDepositPlus,
      bookedBalance: 6000.0,
      accountInterest: 0.95,
      creditLimit: 0.0,
      chainName: undefined,
      chainNumber: 0,
      storeNumber: undefined,
      invoiceFee: 0.0,
      nameIB: "Mitt spar+",
      paymentAccount: "156-2461",
      isActive: true,
      closesAt: undefined,
      hasDirectDebit: false,
      isFixedTerm: false,
    },
  ],
  leaseAccounts: [
    {
      object: {
        id: 548213,
        objectId: "MYB080",
        make: "Kia",
        model: "Kia cee'd Sportswagon 1,6 UEFA Edition",
        serial: "U5YHN813AGL176778",
        year: 2016,
      },
      vendor: {
        addresses: [
          {
            street1: "C/O BGC",
            street2: "BGC-ID: FOR 9401",
            street3: null,
            city: "MALMÖ",
            postalCode: "20536",
            country: "SE",
            type: "Invoice",
          },
          {
            street1: "Box 9505",
            street2: null,
            street3: null,
            city: "MALMÖ",
            postalCode: "20039",
            country: "SE",
            type: "Invoice",
          },
          {
            street1: "Arnegårdsg. 4",
            street2: null,
            street3: null,
            city: "MÖLNDAL",
            postalCode: "43149",
            country: "SE",
            type: "Consignment",
          },
          {
            street1: "Arnegårdsgatan 4",
            street2: null,
            street3: null,
            city: "MÖLNDAL",
            postalCode: "43149",
            country: "Sverige",
            type: "Invoice",
          },
        ],
        id: null,
        customerNumber: 54760,
        name: "Förenade Bil I Göteborg AB",
        contactName: null,
        phones: [
          "031-3341830",
          "031  3341830",
          "031-334 18 30",
          "031-334 18 30",
          "0480-42 58 00",
          "0476-170 70",
          "031-790 00 00",
          "019-10 87 20",
          "090-70 23 80",
          "ejnummer",
          "0500-44 48 00",
          "0340-67 51 45",
        ],
        mobiles: [
          "031  3341830",
          "031-334 18 30",
          "031-334 18 30",
          "FINNSEJ",
          "0480-42 58 00",
          "0476-170 70",
          "031-790 00 00",
          "019-10 87 20",
          "090-70 23 80",
          "ejnummer",
          "0500-44 48 00",
          "0340-67 51 45",
        ],
        eMails: [],
      },
      paymentAccount: "",
      coApplicantName: null,
      effectiveInterest: 0,
      hasDirectDebit: false,
      hasInsurance: false,
      contractPeriod: 36,
      elapsedPeriod: 10,
      openDate: "2018-07-01T00:00:00",
      displayNumber: "1074604",
      accountNumber: "681617",
      accountType: AccountType.PrivateLeasing,
      bookedBalance: -91009,
      accountInterest: 4.15,
      creditLimit: 143421,
      chainName: null,
      chainNumber: 0,
      storeNumber: null,
      invoiceFee: 0,
      nameIB: "Privatleasing",
      accountPersonalName: "My Porsche",
    },
    {
      accountStatus: AccountStatus.AccountClosed,
      closeDate: "2024-04-04",
      object: {
        id: 548213,
        objectId: "AK0001",
        make: "Porsche",
        model: "911 GT3",
        serial: "U5YHN813AGL176778",
        year: 2016,
      },
      vendor: {
        addresses: [
          {
            street1: "C/O BGC",
            street2: "BGC-ID: FOR 9401",
            street3: null,
            city: "MALMÖ",
            postalCode: "20536",
            country: "SE",
            type: "Invoice",
          },
          {
            street1: "Box 9505",
            street2: null,
            street3: null,
            city: "MALMÖ",
            postalCode: "20039",
            country: "SE",
            type: "Invoice",
          },
          {
            street1: "Arnegårdsg. 4",
            street2: null,
            street3: null,
            city: "MÖLNDAL",
            postalCode: "43149",
            country: "SE",
            type: "Consignment",
          },
          {
            street1: "Arnegårdsgatan 4",
            street2: null,
            street3: null,
            city: "MÖLNDAL",
            postalCode: "43149",
            country: "Sverige",
            type: "Invoice",
          },
        ],
        id: null,
        customerNumber: 54760,
        name: "Förenade Bil I Göteborg AB",
        contactName: null,
        phones: [
          "031-3341830",
          "031  3341830",
          "031-334 18 30",
          "031-334 18 30",
          "0480-42 58 00",
          "0476-170 70",
          "031-790 00 00",
          "019-10 87 20",
          "090-70 23 80",
          "ejnummer",
          "0500-44 48 00",
          "0340-67 51 45",
        ],
        mobiles: [
          "031  3341830",
          "031-334 18 30",
          "031-334 18 30",
          "FINNSEJ",
          "0480-42 58 00",
          "0476-170 70",
          "031-790 00 00",
          "019-10 87 20",
          "090-70 23 80",
          "ejnummer",
          "0500-44 48 00",
          "0340-67 51 45",
        ],
        eMails: [],
      },
      paymentAccount: "",
      coApplicantName: null,
      effectiveInterest: 0,
      hasDirectDebit: false,
      hasInsurance: false,
      contractPeriod: 36,
      elapsedPeriod: 10,
      openDate: "2018-07-01T00:00:00",
      displayNumber: "2074604",
      accountNumber: "681618",
      accountType: AccountType.PrivateLeasing,
      bookedBalance: -91009,
      accountInterest: 4.15,
      creditLimit: 143421,
      chainName: null,
      chainNumber: 0,
      storeNumber: null,
      invoiceFee: 0,
      nameIB: "Privatleasing",
    },
  ],
  creditAccountsError: { message: undefined },
  depositAccountsError: { message: undefined },
  leaseAccountsError: { message: undefined },
  privateLoanAccountsError: { message: undefined },
  securedLoanAccountsError: { message: undefined },
};

import { tx } from "netbank-shared/src/libs/i18n";
import { ILoanAccount } from "netbank-shared/src/libs/models/Engagements";
import { AccountStatus } from "netbank-shared/src/libs/models/Engagements/Account";
import React from "react";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import privateLoanStyles from "./Loan.scss";
import styles from "../Page.scss";

export const renderMaturityDate = (currentAccount: ILoanAccount, maturityDateTitle?: string) => {
  const { loanMaturityDate } = currentAccount;
  if (!loanMaturityDate) return undefined;

  const date = new Date(loanMaturityDate).toLocaleDateString(tx("routing.locale"));
  const title = maturityDateTitle || tx("loan.maturityDateTitle");

  return (
    <div className={privateLoanStyles.loanCoreInformationContainer}>
      <span className={styles.templateTitle}>{title}</span>
      <h3>
        {date}
      </h3>
    </div>);
};

export const renderCloseDate = (currentAccount: ILoanAccount, closedDateTitle?: string) => {
    const { closeDate } = currentAccount;
    if (currentAccount.accountStatus !== AccountStatus.AccountClosed || !closeDate) return undefined;
  
    const date = new Date(closeDate).toLocaleDateString(tx("routing.locale"));
    const title = closedDateTitle || tx("loan.closedDateTitle");
  
    return (
      <p>
        <span className={styles.bold}>{title}: </span>
        {date}
      </p>
    );
  };
  
export const renderClosedAccountInfo = (currentAccount: ILoanAccount, closedAccountInfo?: string) => {
    const { accountStatus } = currentAccount;
  
    if (!currentAccount || accountStatus !== AccountStatus.AccountClosed) return undefined;
    const text = closedAccountInfo || tx("loan.closedAccountInfo");
    return <HtmlContent html={text} disableTemplating className={`${privateLoanStyles.closedLoanNotification}`} />;
  };                          
export enum ContentType {
  Page = "Page",
  Block = "Block",
  AccordionBlock = "AccordionBlock",
  DividerBlock = "DividerBlock",
  BasicTextBlock = "BasicTextBlock",
  BasicInfoBlock = "BasicInfoBlock",
}

export enum KreditzFlowMessages {
  BankSelection = "",
  SelectedBankCancelled = "selected bank cancelled",
  BankSelected = "bank selected",
  FormSubmitted = "account verification form submitted",
  AccountList = "banking authentication finished"
}

export enum BankAccountControlFlowState {
  Inactive = "Inactive",
  Started = "Started",
  FetchingIFrameData = "FetchingIFrameData",
  IFrameDataLoaded = "IFrameDataLoaded",
  KreditzInControl = "KreditzInControl",
  PollingResult = "PollingResult",
  Completed = "Completed",
  Error = "Error",
  Final = "Final",
}

export enum Locale {
  sv = "sv-SE",
  fi = "fi-FI",
  no = "nb-NO",
  dk = "da-DK",
}

export enum ISOCode {
  SE = "SE",
  DK = "DK",
}

export enum Lang {
  en = "en",
  sv = "sv",
  fi = "fi",
  no = "no",
  dk = "dk",
}

export enum Environment {
  SIT = "SIT",
  PreProduction = "PreProduction",
  Production = "Production",
}
export enum EvryFunction {
  showCard = "show-card",
  showPin = "show-pin",
}

export enum UrlType {
  MailTo = "mailto",
  Tel = "tel",
  File = "file",
  PDFFile = "pdfFile",
  Internal = "internal",
  External = "external",
}

export enum FileType {
  Url = "url",
  Base64 = "base64",
}

export enum PageType {
  MessageInbox = "MessageInboxPage",
  NewMessage = "NewMessagePage",
  OffersPage = "OffersPage",
  LoanTopUpPage = "LoanTopUpPage",
  CardSettingsPage = "CardSettingsPage",
  StandardPage = "StandardPage",
  HomePage = "HomePage",
  NotFoundPage = "404Page",
  CardTransitPage = "CardTransitPage",
  DepositTransitPage = "DepositTransitPage",
  LeasingTransitPage = "LeasingTransitPage",
  LoanTransitPage = "LoanTransitPage",
  PrivateLoanTransitPage = "PrivateLoanTransitPage",
  SecuredLoanTransitPage = "SecuredLoanTransitPage",
  TermsPage = "TermsPage",
  CardTemplatePage = "CardTemplatePage",
  DepositTemplatePage = "DepositTemplatePage",
  LeasingTemplatePage = "LeasingTemplatePage",
  LoanTemplatePage = "LoanTemplatePage",
  PrivateLoanTemplatePage = "PrivateLoanTemplatePage",
  SecuredLoanTemplatePage = "SecuredLoanTemplatePage",
  ContainerPage = "ContainerPage",
  ClosingOfDepositAccountPage = "ClosingOfDepositAccountPage",
  CustomerCompliancePage = "CustomerCompliancePage",
}

export enum StandardPageType {
  TransferPage = "TransferPage",
  Default = "Default",
}

export enum CardActionBlockTypes {
  AccordionActivateCardBlock = "AccordionActivateCardBlock",
  AccordionBlockCardBlock = "AccordionBlockCardBlock",
  AccordionEvryBlock = "AccordionEvryBlock",
  AccordionOrderCardBlock = "AccordionOrderCardBlock",
}

export enum LoanActionBlockTypes {
  AccordionTerminationAmountBlock = "AccordionTerminationAmountBlock",
}

export enum SelfServiceButtonType {
  Link = "link",
  OfferLink = "offer-link",
  Modal = "modal",
  ClosingDepositLink = "closing-deposit-link",
  TransferFromDepositLink = "transfer-from-deposit-link",
  TransferFromCreditLink = "transfer-from-credit-link",
}

export enum TransferStatus {
  QuotaExceeded = "QuotaExceeded",
  NoFunding = "NoFunding",
  ValidationFailed = "ValidationFailed",
  Succeeded = "Succeeded",
  Enqueued = "Enqueued",
  Failed = "Failed",
  PendingTransfer = "PendingTransfer",
  SystemClosed = "SystemClosed",
  UnverifiedAccountPolicyFail = "UnverifiedAccountPolicyFail",
  Pending = "Pending",
  IllegalForeignAccount = "IllegalForeignAccount",
}

export enum TransferStatusType {
  Positive = 0,
  Negative = 1,
}

export enum Colors {
  Primary = "primary",
  BackgroundPrimary = "backgroundPrimary",
  Red = "red",
  Mercury = "mercury",
  Boulder = "boulder",
  Black = "black",
  White = "white",
  BorderGrey = "borderGrey",
  Botticelli = "botticelli",
  Tundora = "tundora",
  Sinbad = "sinbad",
  BlackSqueeze = "blackSqueeze",
  Jellybean = "jellybean",
  MediumSky = "mediumSky",
  DarkSky = "darkSky",
  LightError = "lightError",
  LightWarning = "lightWarning",
  MilkPunch = "milkPunch",
  Alto = "alto",
  Silver = "silver",
  Turquoise = "turquoise",
  SurfieGreen = "surfieGreen",
  DoveGrey = "doveGrey",
  AccesibleSky = "accesibleSky",
  LimeGreen = "limeGreen",
  Sunglow = "sunglow",
  LightSuccess = "lightSuccess",
}

export enum MessageSender {
  caseHandler = "Case Handler",
  santander = "Santander",
}

export enum DocumentType {
  Any = "ANY",
  Contract = "CONTRACT",
  Invoice = "INVOICE",
  DataPortability = "DATAPORTABILITY",
  Letter = "LETTER",
  InsuranceLetter = "INSURANCE_LETTER",
  YearlyStatement = "YEARLY_STATEMENT",
  YearlyStatementCRS = "YEARLY_STATEMENT_CRS",
  YearlyStatementFATCA = "YEARLY_STATEMENT_FATCA",
  Transactions = "TRANSACTIONS_DOCUMENT",
}

export enum AccountType {
  Credit = "Credit",
  PrivateLoan = "PrivateLoan",
  SecuredLoan = "SecuredLoan",
  Deposit = "Deposit",
  PrivateLeasing = "PrivateLeasing",
}

export enum DepositType {
  MyDeposit = "MyDeposit",
  MyDepositPlus = "MyDepositPlus",
  FTD6 = "FixedTermDeposit6",
  FTD12 = "FixedTermDeposit12",
  FTD24 = "FixedTermDeposit24",
}

export enum LoanType {
  Loan = "Loan",
  PrivateLoan = "PrivateLoan",
  SecuredLoan = "SecuredLoan",
}

export enum Product {
  Credit = "Credit",
  ConsumerLoan = "ConsumerLoan",
  CarLoan = "CarLoan",
  Deposit = "Deposit",
  PrivateLeasing = "PrivateLeasing",
}

export enum Limits {
  invoice = 8,
  Letters = 8,
}

export enum AccountRedemptionStatus {
  Unknown = "Unknown",
  PendingTransactions = "PendingTransactions",
  NoRedemptionInfo = "NoRedemptionInfo",
  PendingRedemption = "PendingRedemption",
  Redeemable = "Redeemable",
  BankIdFailed = "BankIdFailed",
  BankIdCancelled = "BankIdCancelled",
  BankIdPending = "BankIdPending",
  BankIdSigned = "BankIdSigned",
  RedemptionSuccessful = "RedemptionSuccessful",
  RedemptionFailed = "RedemptionFailed",
}

export enum ApplicantEmploymentType {
  None = "",
  PermanentPosition = "PermanentPosition",
  TemporaryPosition = "TemporaryPosition",
  SelfEmployed = "SelfEmployed",
  Student = "Student",
  Retired = "Retired",
  Unemployed = "Unemployed",
}

export enum SourceOfFunds {
  None = "",
  Salary = "Salary",
  Pension = "Pension",
  Savings = "Savings",
  SocialBenefits = "SocialBenefits",
  CommercialIncome = "CommercialIncome",
  SaleOfRealEstate = "SaleOfRealEstate",
  SaleOfMovables = "SaleOfMovables",
  InheritanceGifts = "InheritanceGifts",
  YieldInvestments = "YieldInvestments",
  ShareDividend = "ShareDividend",
  Compensation = "Compensation",
  Gambling = "Gambling",
  TheOtherDebtorWillRepayLoan = "TheOtherDebtorWillRepayLoan",
  EducationalSupport = "EducationalSupport",
  Other = "Other",
}

export enum PurposeOfLoan {
  None = "",
  TravelOrLeisure = "TravelOrLeisure",
  RefinanceRepayOtherLoansCredit = "RefinanceRepayOtherLoansCredit",
  RenovationOfHouseCabinOrOtherProperty = "RenovationOfHouseCabinOrOtherProperty",
  HealthExpensesDentistEtc = "HealthExpensesDentistEtc",
  MoveablesVehicleOrRelatedExpenses = "MoveablesVehicleOrRelatedExpenses",
  EducationOrProfessionalTraining = "EducationOrProfessionalTraining",
  WeddingOrFamilyOccasion = "WeddingOrFamilyOccasion",
  ToCoverForPeriodOfUnemployment = "ToCoverForPeriodOfUnemployment",
  RelocationFurnishingSecurityDeposit = "RelocationFurnishingSecurityDeposit",
  Other = "Other",
}

export enum HousingType {
  None = "",
  Condominium = "Condominium",
  OwnedHouse = "OwnedHouse",
  Tenancy = "Tenancy",
  SecondHandApartment = "SecondHandApartment",
  CollectiveHousing = "CollectiveHousing",
}

export enum PendingApplicationState {
  UnexpectedError = "UnexpectedError",
  Scoring = "Scoring",
  Rejected = "Rejected",
  UnderInvestigation = "UnderInvestigation",
  CoParticipantApproval = "CoParticipantApproval",
  WaitingForCoParticipantApproval = "WaitingForCoParticipantApproval",
  PendingAccept = "PendingAccept",
  DownSellPendingAccept = "DownSellPendingAccept",
  WaitingForParticipantAccept = "WaitingForParticipantAccept",
  Accepted = "Accepted",
  ReadyForSigning = "ReadyForSigning",
  WaitingForParticipantSigning = "WaitingForParticipantSigning",
  Disbursement = "Disbursement",
  Completed = "Completed",
}

export enum PendingApplicationApplicantRole {
  Customer = "Customer",
  CoCustomer = "CoCustomer",
}

export enum TopUpFlowStep {
  ApplicantInformationStep = "ApplicantInformationStep",
  ApplicantEmploymentStep = "ApplicantEmploymentStep",
  ApplicantLivingSituationStep = "ApplicantLivingSituationStep",
  Processing = "Processing",
  InsuranceStep = "InsuranceStep",
  PayoutSelectionStep = "PayoutSelectionStep",
  EBAStep = "EBAStep",
  SummaryStep = "SummaryStep",
  DecisionStep = "DecisionStep",
  SigningStep = "SigningStep",
}

export enum OfferProcedureServiceType {
  PpiService = "PpiService",
  TireHotelService = "TireHotelService",
}

export enum AccountTypeAsProductType {
  Credit = "Credit",
  PrivateLoan = "ConsumerLoan",
  SecuredLoan = "CarLoan",
  Deposit = "Deposit",
  PrivateLeasing = "PrivateLeasing",
}

export enum SvgLogoType {
  SantanderLogoRed = "SantanderLogoRed",
  SantanderLogoWhite = "SantanderLogoWhite",
  ChevronRight = "ChevronRight",
  ChevronLeft = "ChevrpnLeft",
  BankId = "BankId",
  Card = "Card",
  Loan = "Loan",
  Deposit = "Deposit",
  Leasing = "Leasing",
  CardTemplateLogo = "CardTemplateLogo",
  DepositTemplateLogo = "DepositTemplateLogo",
  HomeIcon = "HomeIcon",
  TransferIcon = "TransferIcon",
  MessagesIcon = "MessagesIcon",
  OpenMessageIcon = "OpenMessageIcon",
  HamburgerIcon = "HamburgerIcon",
  CloseIcon = "CloseIcon",
  PlusIcon = "PlusIcon",
  MinusIcon = "MinusIcon",
  WriteIcon = "WriteIcon",
  SearchIcon = "SearchIcon",
  SearchIconInput = "SearchIconInput",
  ArchiveIcon = "ArchiveIcon",
  BookmarkRedIcon = "BookmarkRedIcon",
  UndoIcon = "UndoIcon",
  PdfIcon = "PdfIcon",
  SantanderLogoIcon = "SantanderLogoIcon",
  SmallSantanderLogo = "SmallSantanderLogo",
  Error = "ErrorIcon",
  Information = "InformationIcon",
  Important = "ImportantIcon",
  DeleteIcon = "DeleteIcon",
  EditIcon = "EditIcon",
  ExpandPlusIcon = "ExpandPlusIcon",
  ExpandMinusIcon = "ExpandMinusIcon",
  InfoPopover = "InfoPopover",
  Calendar = "CalendarIcon",
  Checkmark = "Checkmark",
}

export enum AccordionType {
  PageView = "PageView",
  AccordionView = "AccordionView",
}

export enum ModalBlock {
  NewTransferRecieverModalBlock = "NewTransferRecieverModalBlock",
  TransactionModalBlock = "TransactionModalBlock",
  DateRangeModalBlock = "DateRangeModalBlock",
  BankAccountControlFlowModalBlock = "BankAccountControlFlowModalBlock",
  UpdateContactInfoModalBlock = "UpdateContactInfoModalBlock",
}

export enum AccordionViewBlock {
  SelectMenuList = "SelectMenuList",
}

export enum FormOperation {
  New = "New",
  Update = "Update",
}

export enum TransactionStatus {
  Reserved = "Reserved",
  Outgoing = "Outgoing",
}

export enum OrderProcedureType {
  None = "",
  ActivatePpi = "ActivatePpi",
  PaymentFreePeriod = "PaymentFreePeriod",
}

export enum ComplianceLanguage {
  ENG = "ENG",
  SWE = "SWE",
  NOR = "NOR",
  DAN = "DAN",
  FIN = "FIN",
}

export enum KreditzEventType {
  BankSelected = "bank selected",
  BankAuthStarted = "banking authentication started",
  BankAuthCancelled = "user cancelled bank authentication",
  BankAuthCompleted = "banking authentication finished",
  FlowCompleted = "User flow completed",
  ServerError = "server error",
}

export enum KreditzStatusType {
  Success = "success",
  Error = "error",
}

export enum Anchors {
  BankAccountControl = "#bank-account-control",
}

export enum QueryStringKeys {
  BankAccountControlFinalStepContentId = "finalStepContentId",
}

export enum XHtmlString {
  DefaultEmpty = "<p></p>",
}

export enum BankIdSignStatus {
  Inactive = "Inactive",
  Initializing = "Initializing",
  Polling = "Polling",
}

export enum ForeignAccountValidationError {
  None = "None",
  UnsupportedBank = "UnsupportedBank",
  UnrecognizedBank = "UnrecognizedBank",
  InvalidClearing = "InvalidClearing",
  InvalidAccountNumber = "InvalidAccountNumber",
}
import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import { BankAccountControlFlowState, KreditzFlowMessages } from "netbank-shared/src/libs/models/Content/Enums";
import styles from "../BankAccountControlFlow.scss";
import React, { useEffect, useRef } from "react";
import IframeResizer from "iframe-resizer-react";
import { handleBankAccountControlEvent } from "netbank-shared/src/libs/utils/bankAccountControlFlow";
import { BACEvents } from "netbank-shared/src/libs/models/Telemetry/BACEvents";

interface BankAccountControlKreditzInControlStepProps {
  setOverflowHidden: (value: boolean) => void;
}

export const BankAccountControlKreditzInControlStep = observer(
  ({ setOverflowHidden }: BankAccountControlKreditzInControlStepProps) => {
    const { uiStore, transferStore, telemetryStore } = useStores();
    const { bankAccountControlPopup } = uiStore;
    const stepTransitionRef = useRef<boolean>();
    const iFrameRef = useRef<any>();
    const kreditzState = useRef<string>(KreditzFlowMessages.BankSelection);
    useEffect(() => {
      setOverflowHidden(false);

      const listener = (event: MessageEvent<string>) => {
        if (event.origin !== bankAccountControlPopup.origin || event.data.startsWith("[iFrameSizer]")) {
          return;
        }
        stepTransitionRef.current = true;
        telemetryStore.trackEvent(BACEvents.VendorInControl, JSON.parse(event.data));

        const currentState = handleBankAccountControlEvent(event.data, transferStore);
        kreditzState.current = currentState;
        if (iFrameRef.current && currentState === KreditzFlowMessages.FormSubmitted) {
          iFrameRef.current.getIframeElement().style.height = "700px";
        }
      };

      window.addEventListener("message", listener);
      return () => {
        window.removeEventListener("message", listener);
      };
    }, [setOverflowHidden]);

    return (
      <IframeResizer
        heightCalculationMethod="min"
        onError={(e) => {
          telemetryStore.trackSimpleEvent(BACEvents.VendorInControl, "Vendor IFrame failed to load");
        }}
        onLoad={() => {
          telemetryStore.trackSimpleEvent(BACEvents.VendorInControl, "Vendor IFrame in control");
          uiStore.setBankAccountControlFlowState(BankAccountControlFlowState.KreditzInControl);
        }
        }
        checkOrigin={[bankAccountControlPopup.origin]}
        forwardRef={iFrameRef}
        className={styles.kreditzIFrame}
        onResized={(resizeEvent) => {
          if (kreditzState.current === KreditzFlowMessages.BankSelection) {
            return;
          }
          if (kreditzState.current === KreditzFlowMessages.AccountList || kreditzState.current === KreditzFlowMessages.BankSelected) {
            resizeEvent.iframe.style.height = "1700px";
          }
          else{
            resizeEvent.iframe.style.height = "700px";
          }
        }}
        tolerance={20}
        style={{
          display:
            uiStore.bankAccountControlflowState === BankAccountControlFlowState.IFrameDataLoaded ? "none" : "block",
        }}
        src={bankAccountControlPopup.source}
      />
    );
  }
);

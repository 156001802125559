import dayjs from "dayjs";
import { IInvoiceDocument } from "libs/models/Invoice/Invoice";
import { action, makeObservable, observable } from "mobx";
import { EngagementsApi } from "../libs/api";
import { Limits } from "../libs/models/Content/Enums";
import { IInvoiceStatement } from "../libs/models/Engagements/Invoice";
import { createAutoDownloadTempLink } from "../libs/utils";
import { TelemetryStore } from "./TelemetryStore";
import { InvoiceTelementryEvent } from "../libs/models/Telemetry/InvoiceTelemetryEvents";

export class InvoiceStore {
  engagementsApi: EngagementsApi;

  telemetry: TelemetryStore;

  constructor(
    engagementsApi: EngagementsApi,
    telemetryStore: TelemetryStore,
  ) {
    this.engagementsApi = engagementsApi;
    this.telemetry = telemetryStore;
    makeObservable(this);
  }

  @observable
  invoiceStatements?: IInvoiceStatement[] = [];

  @observable
  currentAccountNumber?: string;

  @observable
  offset: number = 0;

  @observable
  limit: number = Limits.invoice;

  @observable
  invoiceArchiveFrontendIndex: number = Limits.invoice;

  @observable
  hasNextPage: boolean = false;

  @observable
  fetchingInvoices: boolean = false;

  getAccountInvoices = async (accountNumber: string, reset: boolean) => {
    this.fetchingInvoices = true;
    if (reset) {
      this.currentAccountNumber = accountNumber;
      this.invoiceStatements = [];
      this.offset = 0;
      this.setInvoiceArchiveFrontendIndex(this.limit);
      this.hasNextPage = false;
    }

    if (accountNumber === this.currentAccountNumber && !reset) {
      this.offset += 1;
    }

    const response = await this.engagementsApi.getAccountInvoices(accountNumber, this.offset, this.limit);

    if (response?.ok) {
      if (response.data?.invoices) {
        if (accountNumber !== this.currentAccountNumber) {
          this.invoiceStatements = response.data.invoices;
          this.setInvoiceArchiveFrontendIndex(this.limit);
          if (response.data.invoices.length >= this.limit) {
            this.hasNextPage = true;
          }
        } else {
          const currentInvoiceStatements: IInvoiceStatement[] = this.invoiceStatements || [];
          this.invoiceStatements = currentInvoiceStatements.concat(response.data.invoices);
          if (this.offset > 0) {
            this.setInvoiceArchiveFrontendIndex(this.limit * (this.offset + 1));
          }
          this.invoiceStatements.slice().sort((a, b) => {
            if (a.maturityDate === undefined && b.maturityDate === undefined) return 0;
            if (a.maturityDate === undefined) return -1;
            if (b.maturityDate === undefined) return 1;
            return dayjs(a.maturityDate).diff(dayjs(b.maturityDate));
          });

          if (response.data.invoices.length < this.limit) {
            this.hasNextPage = false;
          } else {
            this.hasNextPage = true;
          }
        }
      }
    } else if (response?.status === 404) {
      this.hasNextPage = false;
      if (accountNumber !== this.currentAccountNumber) {
        this.invoiceStatements = [];
      }
    }
    this.fetchingInvoices = false;
  };

  getInvoiceDocument = async (
    accountNumber: string,
    invoice: IInvoiceStatement
  ): Promise<IInvoiceDocument | undefined> => {
    const response = await this.engagementsApi.getInvoicePdf(accountNumber, invoice.documentId);

    if (response?.ok && response.data) {
      const blob = new Blob([response.data], { type: "application/pdf" });
      const fileNameBase = "Santander-Consumer-Bank-invoice";
      const fileName = invoice.maturityDate
        ? `${fileNameBase}-${dayjs(invoice.maturityDate).format("YYYY-MM-DD")}.pdf`
        : `${fileNameBase}.pdf`;

      return {
        fileName,
        fileData: blob,
      };
    }
    this.telemetry.trackEvent(InvoiceTelementryEvent.DocumentDownloadFailed, {
      message: response?.originalError?.message,
      statusCode: response?.status,
      data: response?.data,
      documentId: invoice.documentId,
      accountNumber: accountNumber
    });
    return undefined;
  };

  getInvoiceDocumentMobile = (accountNumber: string, invoice: IInvoiceStatement) => {
    return this.getInvoiceDocument(accountNumber, invoice);
  };

  getInvoiceDocumentWeb = async (accountNumber: string, invoice: IInvoiceStatement, download: boolean) => {
    const invoiceDocument = await this.getInvoiceDocument(accountNumber, invoice);

    if (invoiceDocument) {
      const objectUrl = URL.createObjectURL(invoiceDocument.fileData);
      createAutoDownloadTempLink(invoiceDocument.fileName, objectUrl, download);
    }
  };

  getActiveInvoice(): IInvoiceStatement | undefined {
    const activeInvoice =
      this.invoiceStatements && this.invoiceStatements.length > 0 ? this.invoiceStatements[0] : undefined;

    return activeInvoice;
  }

  getArchivedInvoices(): IInvoiceStatement[] | undefined {
    return this.invoiceStatements?.slice(1) || [];
  }

  setInvoiceArchiveFrontendIndex = (value: number) => {
    this.invoiceArchiveFrontendIndex = value;
  };

  @action
  resetStore = () => {
    this.invoiceStatements = undefined;
    this.currentAccountNumber = undefined;
    this.offset = 0;
    this.invoiceArchiveFrontendIndex = 1;
    this.hasNextPage = false;
    this.fetchingInvoices = false;
  };
}

import { action, computed, makeObservable, observable } from "mobx";
import { EngagementsApi } from "../libs/api";
import { TransferApi } from "../libs/api/TransferApi";
import {
  KreditzEventType,
  BankAccountControlFlowState,
  AccountRedemptionStatus,
  AccountType,
  TransferStatus,
  ForeignAccountValidationError,
} from "../libs/models/Content/Enums";
import {
  IAccountRedemptionResponse,
  IToAccountFields,
  ITransferAccount,
  ITransferAccountFields,
} from "../libs/models/Transfer/Transfer";
import { parseNumber } from "../libs/utils";
import { CreditStore } from "./CreditStore";
import { DepositStore } from "./DepositStore";
import { Store } from "./Store";
import { ICreditAccount, IDepositAccount } from "../libs/models/Engagements";
import { UIStore } from "./UIStore";
import { KreditzEvent } from "libs/models/BankAccountControl/KreditzEvent";
import { ContentStore } from "./ContentStore";
import { BankIdSignStore } from "./BankIdSignStore";
import { UnhealthyServiceEvents } from "../libs/models/Telemetry/UnhealthyServiceEvents";
import { TransferEvent } from "../libs/models/Telemetry/TransferEvents";

export class TransferStore {
  rootStore: Store;

  transferApi: TransferApi;

  engagementsApi: EngagementsApi;

  creditStore: CreditStore;

  depositStore: DepositStore;

  uiStore: UIStore;

  contentStore: ContentStore;

  bankIdSignStore: BankIdSignStore;

  constructor(
    rootStore: Store,
    transferApi: TransferApi,
    engagementsApi: EngagementsApi,
    creditStore: CreditStore,
    depositStore: DepositStore,
    uiStore: UIStore,
    contentStore: ContentStore,
    bankIdSignStore: BankIdSignStore
  ) {
    this.rootStore = rootStore;
    this.transferApi = transferApi;
    this.engagementsApi = engagementsApi;
    this.creditStore = creditStore;
    this.depositStore = depositStore;
    this.uiStore = uiStore;
    this.bankIdSignStore = bankIdSignStore;
    this.contentStore = contentStore;
    makeObservable(this);
  }

  initialAccountState: ITransferAccountFields = {
    clearingNumber: "",
    displayName: "",
    accountNumber: "",
  };

  pendingStatusCode = 202;

  failedStatusCode = 400;

  @observable
  kreditzFlowResultChecker?: NodeJS.Timeout;

  @observable
  setBankAccountControlToToAccount: boolean = false;

  @observable
  loading: boolean = false;

  @observable
  deletingAccount: boolean = false;

  @observable
  creatingSavedAccount: boolean = false;

  @observable
  updatingSavedAccount: boolean = false;

  @observable
  fetchingSavedAccounts: boolean = false;

  @observable
  duplicateSavedAccountError: boolean = false;

  @observable
  validatingAccountRedemption: boolean = false;

  @observable
  loadingAccountRedemption: boolean = false;

  @observable
  fromAccount: ICreditAccount | IDepositAccount | undefined;

  @observable
  possibleOwnToAccounts: IDepositAccount[] = [];

  @observable
  toAccount: IToAccountFields | undefined;

  @observable
  amount: number | string = 0;

  @observable
  savedAccounts: ITransferAccount[] | undefined = undefined;

  @observable
  newAccount: ITransferAccountFields = this.initialAccountState;

  @observable
  updateAccount: ITransferAccountFields | undefined = undefined;

  @observable
  transferStatus?: number = undefined;

  @observable
  requiresSigning: boolean = true;

  @observable
  statusChecker?: NodeJS.Timeout;

  @observable
  accountRedemptionStatusChecker?: NodeJS.Timeout;

  @observable
  errors: { for: string; error: string }[] = [];

  @observable
  accountRedemptionData?: IAccountRedemptionResponse;

  @observable
  accountRedemptionStatusCode?: number;

  @observable
  createSavedToAccountResponseError?: boolean;

  @observable
  createSavedToAccountValidationError?: ForeignAccountValidationError;

  @computed
  get withdrawingFromDeposit(): boolean {
    return this.fromAccount?.accountType === AccountType.Deposit;
  }

  @computed
  get savedAccountsViableForTranfer(): ITransferAccount[] | undefined {
    return this.withdrawingFromDeposit
      ? this.savedAccounts?.filter((acc) => acc.isAccountVerified)
      : this.savedAccounts;
  }

  @computed
  get amountValue(): number {
    return typeof this.amount === "string" ? parseNumber(this.amount) : this.amount;
  }

  @action
  setFromAccount = (value: string) => {
    this.fromAccount = [...this.creditStore.creditAccounts, ...this.depositStore.depositAccounts].find(
      (account) => account.accountNumber === value || account.displayNumber === value
    );

    if (this.fromAccount?.accountNumber === this.toAccount?.accountNumber) {
      this.toAccount = undefined;
    }

    if (this.fromAccount?.accountType === AccountType.Deposit) {
      this.possibleOwnToAccounts = this.depositStore.depositAccounts.filter(
        (account) =>
          account.accountNumber !== value &&
          account.displayNumber !== value &&
          account.isActive &&
          !account.closesAt &&
          !account.isAmlFrozen
      );
    } else {
      this.possibleOwnToAccounts = [];
    }

    if (this.toAccount?.accountNumber === value || this.toAccount?.displayNumber === value) {
      this.setToAccount(undefined);
    }
    return this.fromAccount;
  };

  @action
  setToAccount = (accountNumber?: string) => {
    const toSavedAccount = this.savedAccounts?.find((account) => account.accountNumber === accountNumber);

    if (toSavedAccount) {
      this.toAccount = toSavedAccount;
      return;
    }

    if (!accountNumber) {
      this.toAccount = undefined;
    }

    const toOwnAccount = [...this.creditStore.creditAccounts, ...this.depositStore.depositAccounts]?.find(
      (account) => account.accountNumber === accountNumber || account.displayNumber === accountNumber
    );

    if (toOwnAccount) {
      this.toAccount = {
        accountNumber: toOwnAccount.accountNumber,
        displayName: toOwnAccount.name || toOwnAccount.nameIB,
        displayNumber: toOwnAccount.displayNumber,
        isFixedTermDeposit: toOwnAccount.accountType === AccountType.Deposit && toOwnAccount.isFixedTerm,
        accountPersonalName: toOwnAccount.accountPersonalName,
      };
    }
  };

  @action
  setAmount = (value: number | string) => {
    this.amount = value;
  };

  @action
  setNewAccount = (account?: ITransferAccountFields) => {
    this.newAccount = account || this.initialAccountState;
    this.duplicateSavedAccountError = false;
    this.createSavedToAccountResponseError = false;
  };

  @action
  setUpdateAccount = (account?: ITransferAccountFields) => {
    this.updateAccount = account;
  };

  @action
  setTransferStatus = (status?: number) => {
    this.transferStatus = status;
  };

  @action
  setAccountRedemptionStatusCode = (status?: number) => {
    this.accountRedemptionStatusCode = status;
  };

  @action
  cancelTransaction = () => {
    if (this.statusChecker) {
      clearInterval(this.statusChecker);
    }
    if (this.accountRedemptionStatusChecker) {
      clearInterval(this.accountRedemptionStatusChecker);
    }
    this.bankIdSignStore.clear();
    this.setTransferStatus(undefined);
    this.setAccountRedemptionStatusCode(undefined);
    this.loading = false;
    this.loadingAccountRedemption = false;
  };

  @action
  resetTransaction = (removeBankIdHash = false) => {
    this.setTransferStatus(undefined);
    this.setFromAccount("");
    this.setToAccount("");
    this.setAmount(0);
    if (removeBankIdHash) {
      this.rootStore.removeBankIdHash();
    }
  };

  @action
  resetAccountRedemption = () => {
    this.accountRedemptionData = undefined;
    this.accountRedemptionStatusCode = undefined;
    this.loadingAccountRedemption = false;
    this.validatingAccountRedemption = false;
    this.toAccount = undefined;
  };

  @action
  handleKreditzEvent = (event: KreditzEvent) => {
    if (event.eventType === KreditzEventType.FlowCompleted) {
      if (this.kreditzFlowResultChecker !== undefined) {
        clearInterval(this.kreditzFlowResultChecker);
      }

      this.uiStore.setBankAccountControlFlowState(BankAccountControlFlowState.PollingResult);
      let count = 0;
      this.kreditzFlowResultChecker = setInterval(async () => {
        count += 1;
        if (count >= 10) {
          clearInterval(this.kreditzFlowResultChecker);
          this.uiStore.setBankAccountControlFlowState(BankAccountControlFlowState.Error);
          return;
        }
        const result = await this.transferApi.getTransferAccountForCase(event.caseId);
        if (result?.ok && result.data?.account !== undefined) {
          const { account } = result.data;
          account.isAccountVerified = true;
          this.addOrEditSavedAccount(account);
          if (this.setBankAccountControlToToAccount) {
            this.toAccount = account;
            this.setBankAccountControlToToAccount = false;
          }
          if (this.uiStore.bankAccountControlFinalStepVariant) {
            this.uiStore.setBankAccountControlFlowState(BankAccountControlFlowState.Final);
          }
          else {
            this.uiStore.setBankAccountControlFlowState(BankAccountControlFlowState.Completed);
          }
          clearInterval(this.kreditzFlowResultChecker);
        }
      }, 5000);
    }

    if (event.eventType === KreditzEventType.ServerError) {
      this.uiStore.setBankAccountControlFlowState(BankAccountControlFlowState.Error);
    }
  };

  // API calls

  @action
  initializeKreditzFlow = async () => {
    this.uiStore.setBankAccountControlFlowState(BankAccountControlFlowState.FetchingIFrameData);
    const response = await this.transferApi.getBankAccountControlCase();
    if (response?.ok && response.data !== undefined) {
      this.uiStore.setBankAccountControlPopup({
        source: response.data.source,
        origin: response.data.origin,
      });
      this.uiStore.setBankAccountControlFlowState(BankAccountControlFlowState.IFrameDataLoaded);
      return response.data;
    }

    this.uiStore.setBankAccountControlFlowState(BankAccountControlFlowState.Error);
    return null;
  };

  @action
  getSavedAccounts = async () => {
    this.fetchingSavedAccounts = true;
    const { strictAccountValidationEnabled } = this.rootStore.contentStore;
    const response = await this.transferApi.getTransferAccounts();
    if (response?.ok && response.data?.accounts) {
      const savedAccounts = strictAccountValidationEnabled
        ? response.data?.accounts.filter(acc => acc.isAccountValid)
        : response.data.accounts;
      this.savedAccounts = savedAccounts
      this.fetchingSavedAccounts = false;
      return savedAccounts;
    }
    this.savedAccounts = undefined;
    this.fetchingSavedAccounts = false;
    return null;
  };

  @action
  createSavedAccount = async (setToAccount = false) => {
    if (!this.newAccount) return false;
    this.creatingSavedAccount = true;
    if (
      this.savedAccounts &&
      this.savedAccounts.findIndex(
        (a) => a.clearingNumber === this.newAccount.clearingNumber && a.accountNumber === this.newAccount.accountNumber
      ) !== -1
    ) {
      this.duplicateSavedAccountError = true;
      this.creatingSavedAccount = false;
      return false;
    }
    const response = await this.transferApi.createTransferAccount(
      this.newAccount.clearingNumber,
      this.newAccount.accountNumber,
      this.newAccount.displayName
    );
    this.creatingSavedAccount = false;

    if (!response?.ok) {
      this.createSavedToAccountResponseError = true;
      return false;
    }

    if (response.data?.validationError === ForeignAccountValidationError.None) {
      await this.getSavedAccounts();
      if (setToAccount) {
        this.setToAccount(this.newAccount.displayName);
      }
      this.setNewAccount(undefined);
    }
    else {
      this.createSavedToAccountValidationError = response?.data?.validationError;
      return false;
    }

    return true;
  };

  @action
  updateSavedAccount = async (accountToUpdate: ITransferAccount) => {
    if (!this.updateAccount) return;
    this.updatingSavedAccount = true;
    const { clearingNumber, accountNumber, displayName } = this.updateAccount;
    const response = await this.transferApi.updateTransferAccount(clearingNumber, accountNumber, displayName);
    if (response?.ok) {
      this.savedAccounts = this.savedAccounts?.map((account) =>
        account.clearingNumber === accountToUpdate.clearingNumber &&
          account.accountNumber === accountToUpdate.accountNumber
          ? { ...account, clearingNumber, accountNumber, displayName }
          : account
      );
      this.setUpdateAccount(undefined);
    }
    this.updatingSavedAccount = false;
  };

  @action
  deleteSavedAccount = async (account: ITransferAccount) => {
    this.deletingAccount = true;
    const response = await this.transferApi.deleteTransferAccount(
      account.clearingNumber,
      account.accountNumber,
      account.displayName
    );
    if (response?.ok) {
      if (
        this.toAccount?.clearingNumber === account.clearingNumber &&
        this.toAccount.accountNumber === account.accountNumber
      ) {
        this.setToAccount(undefined);
      }
      this.savedAccounts = this.savedAccounts?.filter(
        (a) => !(a.clearingNumber === account.clearingNumber && a.accountNumber === account.accountNumber)
      );
    }
    this.deletingAccount = false;
  };

  @action
  updateEngagements = async () => {
    await this.rootStore.getEngagements(true);
  };

  checkTransferStatus = async (transactionId: number) => {
    const response = await this.transferApi.getTransferStatus(transactionId);
    if (response?.data) {
      // undefined or nullchecks since 0 is falsy in JS
      const statusCode =
        response.data.transactionStatusCode !== undefined && response.data.transactionStatusCode !== null
          ? response.data.transactionStatusCode
          : response.data.status;

      if (response.data.transactionStatus === TransferStatus.Succeeded) {
        if (this.statusChecker) clearInterval(this.statusChecker);
        await this.rootStore.getEngagements(true);
      }

      this.setTransferStatus(statusCode);
      return response.data.transactionStatus !== TransferStatus.Pending;
    }
    return false;
  };

  makeTransfer = async (sameDeviceTransfer: boolean) => {
    this.loading = true;

    if (this.fromAccount && this.toAccount) {
      const fromAccountNumber = this.fromAccount.accountNumber;
      const toClearingNumber = this.toAccount?.clearingNumber;
      const toAccountNumber = this.toAccount?.accountNumber;

      const response = await this.transferApi.makeTransfer(
        fromAccountNumber,
        toAccountNumber,
        this.amountValue,
        sameDeviceTransfer,
        true,
        toClearingNumber
      );
      if (response?.ok && response.data) {
        this.setTransferStatus(this.pendingStatusCode);
        this.requiresSigning = response.data.requiresSigning;

        this.bankIdSignStore.bankIdStartState = response.data.bankIdStartState;
        this.bankIdSignStore.bankIdAutostartToken = response.data.autoStartToken;

        this.statusChecker = setInterval(async () => {
          const check = response.data && (await this.checkTransferStatus(response.data.transactionId));
          if (check) {
            if (this.statusChecker) clearInterval(this.statusChecker);
            this.loading = false;
          }
        }, 3000);

        if (this.requiresSigning) {
          this.rootStore.telemetryStore.trackEvent(TransferEvent.Initiated, {
            transactionId: response.data.transactionId,
          });
          this.handleSign(sameDeviceTransfer, false);
        }
      } else {
        this.rootStore.telemetryStore.trackEvent(UnhealthyServiceEvents.MoneyTransferTransferFailed, {
          statusCode: response?.status,
          withdrawingFromDeposit: this.withdrawingFromDeposit
        });
        this.transferStatus = this.failedStatusCode;
        this.loading = false;
      }
    }
  };

  validateAccountRedemption = async () => {
    this.accountRedemptionData = undefined;
    this.setAccountRedemptionStatusCode(undefined);
    if (!this.rootStore.depositStore.currentAccount?.displayNumber) return;
    this.validatingAccountRedemption = true;

    const response = await this.transferApi.createAccountRedemption(
      false,
      this.rootStore.depositStore.currentAccount.displayNumber,
      "",
      true,
      true,
      ""
    );
    if (response?.ok && response.data) {
      this.accountRedemptionData = response.data;
    } else {
      this.accountRedemptionData = {
        status: AccountRedemptionStatus.Unknown,
        statusCode: 400,
      };
    }
  };

  checkAccountRedemptionStatus = async (transactionId: string) => {
    const response = await this.transferApi.getAccountRedemptionStatus(transactionId);
    if (response?.data) {
      const statusCode = response.data.transactionStatusCode !== undefined ? response.data.transactionStatusCode : 400;

      this.setAccountRedemptionStatusCode(statusCode);
      const isFinalStatusCode =
        response.data.transactionStatus !== AccountRedemptionStatus.BankIdPending &&
        response.data.transactionStatus !== AccountRedemptionStatus.BankIdSigned;
      if (response.data.transactionStatus === AccountRedemptionStatus.RedemptionSuccessful) {
        await this.depositStore.invalidateCachedAccounts();
      }
      return isFinalStatusCode;
    }
    return false;
  };

  addOrEditSavedAccount(account: ITransferAccount) {
    const accountsAreEqual = (x: ITransferAccount, y: ITransferAccount): boolean => {
      return x.accountNumber === y.accountNumber && x.clearingNumber === y.clearingNumber;
    };

    const accountExists = this.savedAccounts?.find((x) => accountsAreEqual(x, account));
    if (accountExists) {
      this.savedAccounts = this.savedAccounts?.map((x) => (accountsAreEqual(x, account) ? account : x));
    } else {
      this.savedAccounts?.push(account);
    }
  }

  handleSign = async (isSameDevice: boolean, isAccountRedemption: boolean) => {
    const handleFailure = () => {
      this.bankIdSignStore.clear();
      this.transferStatus = this.failedStatusCode;
      this.loading = false;
      clearInterval(this.statusChecker);
      this.statusChecker = undefined;

      if (isAccountRedemption) {
        this.loadingAccountRedemption = false;
        this.accountRedemptionStatusCode = 400;
        clearInterval(this.accountRedemptionStatusChecker);
        this.accountRedemptionStatusChecker = undefined;
      }
    }

    this.bankIdSignStore.onQrCodeRefreshFailed = handleFailure;
    this.bankIdSignStore.onCollectSuccess = this.bankIdSignStore.clear;
    this.bankIdSignStore.onCollectFailed = handleFailure;
    if (isSameDevice) {
      this.bankIdSignStore.onAutoStartTokenFetched = (token) => this.rootStore.openBankId(token);
    }

    await this.bankIdSignStore.startSign(this.bankIdSignStore.bankIdStartState, isSameDevice, "TRANSFER_API");
  }

  createAccountRedemption = async (loggedUsingSameDevice: boolean) => {
    if (!this.rootStore.depositStore.currentAccount?.displayNumber || this.toAccount === undefined) return;

    this.loadingAccountRedemption = true;
    const response = await this.transferApi.createAccountRedemption(
      true,
      this.rootStore.depositStore.currentAccount.displayNumber,
      this.toAccount.accountNumber,
      loggedUsingSameDevice,
      true,
      this.toAccount.clearingNumber,
    );
    if (response?.ok) {
      if (response.data?.transactionId) {
        await this.checkAccountRedemptionStatus(response.data.transactionId);
      }

      if (response.data?.autoStartToken) {
        this.bankIdSignStore.bankIdAutostartToken = response.data?.autoStartToken;
      }

      if (response.data?.bankIdStartState) {
        this.bankIdSignStore.bankIdStartState = response.data?.bankIdStartState;
      }

      this.handleSign(loggedUsingSameDevice, true);

      this.accountRedemptionStatusChecker = setInterval(async () => {
        const check =
          response.data?.transactionId && (await this.checkAccountRedemptionStatus(response.data.transactionId));
        if (check) {
          if (this.accountRedemptionStatusChecker) {
            clearInterval(this.accountRedemptionStatusChecker);
            this.loadingAccountRedemption = false;
          }
        }
      }, 3000);
    } else {
      this.loadingAccountRedemption = false;
      this.accountRedemptionStatusCode = 400;
    }
  };

  @action
  resetSavedAccounts = () => {
    this.deletingAccount = false;
    this.creatingSavedAccount = false;
    this.updatingSavedAccount = false;
    this.fetchingSavedAccounts = false;
    this.duplicateSavedAccountError = false;
    this.createSavedToAccountResponseError = false;
    this.createSavedToAccountValidationError = undefined;
  };


  @action
  resetStore = () => {
    this.resetTransaction();
    this.resetAccountRedemption();
    this.resetSavedAccounts();
    this.loading = false;
    this.possibleOwnToAccounts = [];
    this.savedAccounts = undefined;
    this.newAccount = this.initialAccountState;
    this.updateAccount = undefined;
    this.requiresSigning = true;
    this.statusChecker = undefined;
    this.accountRedemptionStatusChecker = undefined;
    this.errors = [];
  };
}
